<template>
  <v-app class="honda-registration-wrapper">
    <portal-target name="registration-target" />
    <portal-target name="maintenance-target" />

    <div class="dashboard">
      <app-header v-if="!noHeader" :hideNavMenu="allowImport" />

      <div class="main-body">
        <portal-target name="landbot-target" class="landbot-target" />
        <app-navigation-menu v-if="!allowImport" />
        <div
          :class="{
            'dashboard-body-nav-off': allowImport,
            'dashboard-body-wide': getDrawer && !allowImport,
            'dashboard-body': !getDrawer && !allowImport,
          }"
        >
          <v-snackbar
            :class="`m-snackbar m-${alertResult.type} snackbar-${getDrawer ? 'narrow' : 'wide'}`"
            :timeout="alertResult.timeout || 4000"
            center
            content-class="m-snackbar--content"
            text
            top
            transition="slide-y-transition"
            v-model="alertResultState"
            width="100%"
          >
            <span class="m-snackbar--text">
              <i :class="`${icon} pr-2`"></i>
              <span>{{ t(alertResult.stringId, t(alertResult.content)) }}</span>
            </span>

            <template v-slot:action="{ attrs }">
              <m-btn
                @click="alertResult.onClick"
                class="mr-1 px-2"
                hoverTextColor="gray--darken3"
                v-bind="attrs"
                v-if="alertResult.buttonText && alertResult.onClick"
                variant="outlined"
              >
                <div class="mx-6">
                  <v-icon class="fa-light fa-pen-to-square button-icon" size="19" />
                  <span class="button-text">{{ $t(alertResult.buttonText) }}</span>
                </div>
              </m-btn>
            </template>
          </v-snackbar>

          <router-view v-if="user"></router-view>
        </div>
      </div>
      <drawer-user-menu />
    </div>
    <landbot />
    <dialog-version />
  </v-app>
</template>

<script>
import router from '@/router'

import AppNavigationMenu from './AppNavigationMenu.vue'
import DrawerUserMenu from './DrawerUserMenu.vue'

import AppHeader from '@/components/AppHeader'
import Landbot from '@/components/atom/Landbot.vue'
import DialogVersion from '@/components/dialog/DialogVersion.vue'

export default {
  name: 'Dashboard',
  components: { Landbot, AppHeader, AppNavigationMenu, DrawerUserMenu, DialogVersion },
  data() {
    return {
      verificationResult: null,
      noHeader: true,
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    getDrawer() {
      return this.$store.getters.getDrawerState
    },
    icon() {
      switch (this.alertResult.type) {
        case 'success':
          return 'fa-solid fa-circle-check'
        case 'error':
          return 'fa-solid fa-circle-xmark'
        case 'warning':
          return 'fa-solid fa-circle-exclamation'
        case 'info':
          return 'fa-solid fa-circle-info'
        default:
          return 'fa-solid fa-circle-check'
      }
    },
    /**
     * Get information from store about our group (Dealer)
     */
    group() {
      return this.$store.getters.getGroup
    },
    /**
     * Check permisions to use import page functions
     */
    allowImport() {
      return this.$store.getters.isImportUser
    },
    /**
     * Check permisions to load dealers list
     */
    allowStockAdmin() {
      return this.$store.getters.isStockUser || this.$store.getters.isAdmin
    },
    alertResult() {
      return this.$store.getters.getAlertResult
    },
    alertResultState: {
      get() {
        return this.alertResult.open
      },
      set(value) {
        this.$store.dispatch('setAlertResult', { ...this.alertResult, open: value })
      },
    },
  },
  methods: {
    t(id, content) {
      return this.$t(id, content)
    },
    exit() {
      this.$store.dispatch('logout')
      router.push({ name: 'Login' })
    },
  },
  created() {
    this.noHeader = false
  },
}
</script>

<style lang="scss">
:root {
  --snackbar-success-background: #dbefdc;
  --snackbar-info-background: #d9eafc;
  --snackbar-warning-background: #fff4e2;
  --snackbar-error-background: #fcdcdc;

  --snackbar-success-color: #00803e;
  --snackbar-info-color: #0075eb;
  --snackbar-warning-color: #da9001;
  --snackbar-error-color: #dc182d;
}
.v-snack__wrapper:has(.m-snackbar--content) {
  display: flex;
  justify-content: center;
}
.m-snackbar {
  top: 70px !important;

  &.snackbar-wide,
  &.snackbar-narrow {
    width: calc(100% - var(--drawer-width));
    left: var(--drawer-width);
  }
}

.dashboard{
  overflow: hidden;
}

.m-snackbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .button-text {
    font-weight: 600;
    margin-left: 10px;
  }

  .button-icon {
    font-weight: 500;
    margin-bottom: 3px;
  }

  &.m-success {
    .v-snack__wrapper {
      color: var(--snackbar-success-color);
      background-color: var(--snackbar-success-background) !important;
    }

    button {
      color: var(--snackbar-success-color);
      transition: background-color 0.3s ease;
      font-weight: 600;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }

  &.m-warning {
    .v-snack__wrapper {
      color: var(--snackbar-warning-color);
      background-color: var(--snackbar-warning-background) !important;
    }

    button {
      color: var(--snackbar-warning-color);
      transition: background-color 0.3s ease;
      font-weight: 600;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }

  &.m-error {
    .v-snack__wrapper {
      color: var(--snackbar-error-color);
      background-color: var(--snackbar-error-background) !important;
    }

    button {
      color: var(--snackbar-error-color);
      transition: background-color 0.3s ease;
      font-weight: 600;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
  &.m-info {
    .v-snack__wrapper {
      color: var(--snackbar-info-color);
      background-color: var(--snackbar-info-background) !important;
    }

    button {
      color: var(--snackbar-info-color);
      transition: background-color 0.3s ease;
      font-weight: 600;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }

  .v-snack__wrapper {
    width: 100%;
    height: 60px;
    max-width: 100%;

    border-radius: 0 !important;
    margin: 0 !important;
    padding: 0;

    &:before {
      opacity: 0;
    }

    .m-snackbar--content {
      display: flex;
      justify-content: center;
      font: normal normal 600 15px/20px 'ProximaNovaRegular';
      width: max-content !important;
      max-width: max-content !important;
      margin-right: unset;
    }

    .m-snackbar--text {
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal 600 15px/20px 'ProximaNovaRegular';
    }
  }
}

.main-body {
  position: fixed;
  display: inline-flex;
  width: calc(100%);
  height: calc(100% - 70px);
  overflow-y: auto;
  z-index: 0;
}

.dashboard-body {
  position: relative;
  width: calc(100% - 236px);
  margin-left: 236px;

  &-wide {
    position: relative;
    width: 100%;
    margin-left: 52px;
  }
}

.dashboard-body-nav-off {
  position: relative;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
