<template>
  <div class="revisions form-card ultra-high-size">
    <h3 class="align-left p-t-0 p-b-20 honda-plus-title" style="border-bottom: 1px solid black">
      {{ $t('honda.repair.repair-title') }}
    </h3>

    <v-row no-gutters>
      <div class="d-flex flex-nowrap flex-sm-wrap justify-space-between full-width">
        <div class="d-flex flex-wrap">
          <button
            class="btn btn-block btn-honda-plus btn-radius"
            v-bind:class="{ 'btn-disabled': !request_data.requested }"
            @click="
              request_data.requested = !request_data.requested
              load(false)
            "
          >
            <i class="fas fa-check-circle" aria-hidden="true"></i>
            {{ $t('honda.repair.accepted-rep') }}
          </button>
        </div>
        <div class="text-no-wrap align-self-end mb-1 ml-auto">
          <v-row no-gutters>
            <m-text-field
              class="custom-search flex-grow-1"
              @keydown.enter="load()"
              style="width: 300px"
              hide-details
              outlined
              :placeholder="$t('honda.registration.list.placeholder_search')"
              v-model="searchQuery"
            >
            </m-text-field>
            <m-btn class="btn-primary" @click="load(false)">
              <v-icon class="icon-mapit">?</v-icon>
            </m-btn>
          </v-row>
        </div>
      </div>

      <data-table-base
        id="repairs"
        :loading="loading || loadingModal"
        :headers="columns"
        :items="important_services"
        :searchTerm="searchQuery"
        :loadingText="$t('repairs.table.loading')"
        style="width: 100%"
        :compareFn="compareRepairDates"
        @openModal="openModal"
      />

      <repair-modal
        v-if="activeModal"
        :inspection="modalData.inspection"
        :request="modalData.request"
        :container="modalData.container"
        :customer="modalData.customer"
        :active="activeModal"
        @close="closeModal"
        :step="step"
      />
    </v-row>
  </div>
</template>
<script>
import DataTableBase from '@/components/general/DataTableBase.vue'
import { eventBus } from '@/main'
import RepairModal from './RepairModal'
import { mapGetters } from 'vuex'
import repairOrdersApi from '@/api/repairOrders.api'
import moment from 'moment'

export default {
  components: {
    DataTableBase,
    RepairModal,
  },
  data() {
    var sortOrders = {}

    var columns = [
      {
        text: this.$t('repairs.table.headers.model'),
        value: 'model',
        class: 'text-header',
      },
      {
        text: this.$t('repairs.table.headers.registrationNumber'),
        value: 'plate',
        class: 'text-header',
      },
      {
        text: this.$t('repairs.table.headers.name'),
        value: 'client',
        class: 'text-header',
      },
      {
        text: this.$t('repairs.table.headers.reason'),
        value: 'appointmentReason',
        class: 'text-header',
      },
      {
        text: this.$t('repairs.table.headers.kmNextRevision'),
        value: 'kmnextrevision',
        class: 'text-header text-no-wrap',
        rowClass: 'text-center',
      },
      {
        text: this.$t('repairs.table.headers.date'),
        value: 'appointmentDay',
        class: 'text-header',
        path: 'request.appointment.day',
        sortable: true,
      },
      {
        text: this.$t('repairs.table.headers.time'),
        value: 'appointmentTime',
        class: 'text-header',
      },
      {
        text: this.$t('repairs.table.headers.pickupDay'),
        value: 'pickupDay',
        class: 'text-header',
      },
      {
        text: this.$t('repairs.table.headers.pickupTime'),
        value: 'pickupTime',
        class: 'text-header',
      },
      {
        text: this.$t('repairs.table.headers.repair'),
        value: 'repair',
        align: 'center',
        class: 'text-header',
        rowClass: 'text-center',
      },
    ]

    let now = new Date()
    let minDate = new Date()
    let maxDate = new Date()
    let firstDate = new Date()
    minDate.setDate(now.getDate() + 7)
    maxDate.setDate(now.getDate() + 120)
    if (now.getDay() === 6) firstDate.setDate(now.getDate() + 9)
    else firstDate.setDate(now.getDate() + 8)

    return {
      modalData: {},
      activeModal: false,
      sortKey: 'prevision',
      sortOrders: sortOrders,
      all_services: [],
      important_services: [],
      searchQuery: '',
      columns,
      editMode: null,
      defaultDate: firstDate,
      testEdit: {
        dateSelected: firstDate,
        schedule: 'Mañana',
        comment: '',
      },
      disabledDates: {
        to: minDate, // Disable all dates up to specific date
        from: maxDate, // Disable all dates after specific date
        days: [0], // Disable Saturday's and Sunday's
      },
      request_data: {
        accepted: true,
        cancelled: true,
        rejected: true,
        requested: true,
        pending: true,
        norequestdata: true,
        subagent: false,
      },
      page: 1,
      max: 20,
      bottom: false,
      noMoreResults: false,
      loading: false,
      loadingModal: false,
      ORDERBY: 'PETITION_DESC',
      step: 0,
    }
  },
  props: ['id'],
  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
    isFiltered() {
      return Object.values(this.request_data).filter(Boolean).length > 0
    },
    token() {
      return this.$store.getters.getToken
    },
    user() {
      return this.$store.getters.getUser
    },
  },
  watch: {
    searchQuery: {
      handler() {
        this.filterRepairs()
      },
    },
    bottom(bottom) {
      if (bottom) {
        this.loadMoreRevisions()
      }
    },
  },
  methods: {
    async openModal({ payload }) {
      const id = payload?.request?.repairOrder?.id

      if (!id) {
        console.error('No id found in payload:', payload)
        return
      }

      this.$router.push('/repairs/' + id)
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      self.loadingModal = true

      await repairOrdersApi.getRepairOrderById(id).then((ro) => {
        if (ro) {
          self.loadingModal = false
          self.modalData = {
            request: {
              appointment: {
                ...ro.request.appointment,
                day: moment(ro.request.appointment.day, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              },
              requested: ro.request.requested,
              repairOrder: {
                ...ro.request.repairOrder,
                day: ro.request.repairOrder.day
                  ? moment(ro.request.repairOrder.day, 'DD/MM/YYYY').format('YYYY-MM-DD')
                  : '',
              },
              pickup: ro.request.pickup,
              comment: ro.request.comment,
            },
            inspection: ro.inspection,
            container: {
              id: ro.container.detail.Id,
              legacyId: ro.container.detail.LegacyId,
              detail: JSON.stringify(ro.container.detail),
            },
            customer: {
              detail: JSON.stringify(ro.customer.detail),
            },
          }

          self.activeModal = true
          self.step = 0
          eventBus.$emit('refresh-counters')
        }
      })
    },
    closeModal() {
      this.activeModal = false
      if ('id' in this.$route.params) {
        this.$router.push('/repairs')
        eventBus.$emit('refresh-repair-list')
      }
    },
    filterRepairs() {
      const searchLower = this.searchQuery.toLowerCase()

      this.important_services = this.important_services.filter((ro) => {
        const searchableFilters = [
          ro.container.detail.Model,
          ro.container.detail.Plate,
          ro.customer.detail.Name,
          ro.customer.detail.Surname,
        ]

        return searchableFilters.some((field) =>
          field?.toString().toLowerCase().includes(searchLower),
        )
      })
    },
    async load() {
      this.page = 1
      this.max = 50
      this.noMoreResults = false
      this.loading = true

      try {
        const repairOrders = await repairOrdersApi.listRepairOrders(
          this.user.dealerId,
          this.request_data,
        )

        this.important_services = repairOrders

        this.filterRepairs()

        this.loading = false
        this.page++
      } catch (e) {
        console.log(e)
      }
    },
    reload() {
      this.load()
    },
    //COMPROVAR SI ESTEM AL FINAL DE LA PAGINA
    bottomVisible() {
      const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight

      //console.log(bottomOfPage || pageHeight < visible, scrollY, bottomOfPage, pageHeight, visible);
      return bottomOfPage || pageHeight < visible
    },
    loadMoreRevisions() {
      if (!this.noMoreResults) {
        this.loading = true

        let body = {}
        if (this.searchQuery && this.searchQuery.length > 0) {
          body = {
            groupId: this.user.groupId,
            priority: false,
            request_data: this.request_data,
            search: this.searchQuery,
            status: 0,
            page: this.page,
            max: this.max,
            order: this.ORDERBY,
          }
        } else {
          body = {
            groupId: this.user.groupId,
            priority: false,
            request_data: this.request_data,
            status: 0,
            page: this.page,
            max: this.max,
            order: this.ORDERBY,
          }
        }
      }
    },
    compareRepairDates(valA, valB) {
      const dateA = moment(valA, 'DD/MM/YYYY')
      const dateB = moment(valB, 'DD/MM/YYYY')
      return dateA.diff(dateB)
    },
  },
  created() {
      window.addEventListener('scroll', () => {
      if (!this.loading) {
        this.bottom = this.bottomVisible()
      }
    })
    eventBus.$on('refresh-repair-list', () => {
        this.load(true)
    })
    if ('id' in this.$route.params) {
      this.openModal({ payload: { id: this.$route.params.id, modalId: 0 } })
    } else {
      this.load(true)
    }
  },
  destroyed() {
    eventBus.$off('refresh-repair-list')
  },
}
</script>
<style></style>
