<template>
  <v-card class="align-right px-9 mx-auto" elevation="0" style="width: 900px">
    <v-card-actions class="d-flex justify-end pt-0 pr-5">
      <m-pagination
        v-if="pageCount > 1"
        v-model="currentPage"
        :length="pageCount"
        color="#F1A2AB"/>
    </v-card-actions>
    <v-card elevation="5" width="100%" @click="$emit('onOpenDialog')">
      <pdf :src="src"
          :page="currentPage"
          @num-pages="pageCount = $event"
          @page-loaded="currentPage = $event"
          @loaded="pdfLoaded"
          ref="myPdfComponent"
          :data-cy="uid"
      >
        <template slot="loading">
          {{ $t("common.loading") }}
        </template>
      </pdf>
    </v-card>
    <v-card-actions class="d-flex justify-end pr-5">
      <m-pagination
        v-if="pageCount > 1"
        v-model="currentPage"
        :length="pageCount"
        color="#F1A2AB"/>
    </v-card-actions>
  </v-card>
</template>
<script>
import pdf from "@mapit/vue-pdf";

export default {
  name: "PdfViewerCard",
  props: ['uid', 'pdfPath', 'showPdfViewer', 'code'],
  components: {
    pdf,
  },
  data() {
    return {
      currentPage: 1,
      pageCount: 0,
      src: null,
      zoom: 100,
      panels: [0],
    }
  },
  mounted() {
    this.src = this.$props.pdfPath
  },
  computed: {
    noPrevPage() {
      return this.currentPage <= 1;
    },
    noNextPage() {
      return this.currentPage === this.pageCount;
    }
  },
  methods: {
    prevPage() {
      this.currentPage--;
    },
    nextPage() {
      this.currentPage++;
    },
    printPdf() {
      this.$refs.myPdfComponent.print(100)
    },
    pdfLoaded() {
      this.$root.$emit('pdfLoaded')
    },
  },
  beforeDestroy() {
    Object.assign(this.$data, {
      currentPage: 1,
      pageCount: 0,
      src: null,
      zoom: 100,
      panels: [0]
    });
  }
};
</script>