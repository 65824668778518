<template>
  <div class="full-width px-6" ref="ResumeView" id="ResumeView">
    <v-overlay :value="loadingOfferSummary || loading ">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>

    <div class="actions-wrapper">
      <div class="d-flex relative code-wrapper">
        <div class="d-flex align-center">
          <v-card-title class="item-title p-0" v-if="offer">
            {{ `${offer.name || '-'} ${offer.surname || ''}` }}
          </v-card-title>
          <m-chip
            v-if="chipData"
            :icon="chipData.icon"
            :text="chipData.text"
            :tooltip="chipData.tooltip"
            :variant="chipData.variant"
          />
        </div>
        <v-card-text class="pl-0 pt-0" v-if="offer">
          {{ offer.phone | formatPhone }}
        </v-card-text>
      </div>
      <div class="px-0 action-wrapper">
        <m-btn
          :disabled="loadingOfferSummary || isEditOfferLoading || isEditOfferDisabled"
          :loading="loadingOfferSummary || isEditOfferLoading"
          @click="goToEditOffer"
          class="helper-button"
          hoverTextColor="gray"
          variant="outlined"
          >
          <div class="inner-wrapper">
            <v-icon class="fa-light fa-pen-to-square" size="19"/>
            <span class="inner-text">{{ $t('offer-manager.offers-list.actions.edit') }}</span>
          </div>
        </m-btn>

        <m-btn
          variant="outlined"
          class="helper-button"
          hoverTextColor="gray"
          @click="downloadFile"
          :loading="loadingOfferSummary"
          :disabled="loadingOfferSummary">
          <div class="inner-wrapper">
            <v-icon class="fa-light fa-file-arrow-down" size="19"/>
            <span class="inner-text">{{ $t('offer-manager.offers-list.actions.download') }}</span>
          </div>
        </m-btn>

        <m-btn
          variant="outlined"
          hoverTextColor="gray"
          class="helper-button"
          @click="printPdf"
          :loading="loadingOfferSummary"
          :disabled="loadingOfferSummary">
          <div class="inner-wrapper">
            <v-icon class="fa-light fa-print" size="19"/>
            <span class="inner-text">{{ $t('offer-manager.offers-list.actions.print') }}</span>
          </div>
        </m-btn>
        <label-tooltip :show="canSendEmail" max-width="100%">
          <m-btn
            variant="outlined"
            hoverTextColor="gray"
            class="helper-button--alone"
            @click="openEmailDialog"
            :loading="loadingOfferSummary"
            :disabled="loadingOfferSummary || !canSendEmail">
            <div class="inner-wrapper">
              <v-icon class="fa-light fa-paper-plane" size="19"/>
              <span class="inner-text">{{ $t('offer-manager.offers-list.actions.send-email') }}</span>
            </div>
          </m-btn>
          <template #tooltip>
            <span>{{ $t('offer-manager.email-dialog.tooltip') }}</span>
          </template>
        </label-tooltip>

        <label-tooltip :show="canCreateRegistration" max-width="100%">
          <m-btn
            class="helper-button--alone helper-button--registration"
            hoverTextColor="gray"
            variant="outlined"
            @click="createRegistration"
            :loading="loadingOfferSummary"
            :disabled="loadingOfferSummary || !canCreateRegistration">
            <div class="inner-wrapper">
              <v-icon class="fak fa-m-motorcycle-h" size="19"/>
              <span class="inner-text">{{ $t('offer-manager.offers-list.actions.create-registration') }}</span>
            </div>
          </m-btn>

          <template #tooltip>
            <span>{{ $t('offer-manager.offers-list.create-registration.disabled') }}</span>
          </template>
        </label-tooltip>
      </div>
    </div>
    <div class="d-flex pdf-table-container py-4">
      <pdf-viewer
        :key="pdfKey"
        :pdf-path="pdfUrl"
        :show-pdf-viewer="pdfUrl"
        :code="code"
        class="px-0 px-md-0 py-0"
        uid="pdf-viewer-offer"
      />
      <div v-if="$slots['extra-content']">
        <slot name="extra-content"/>
      </div>
    </div>

    <mapit-dialog
      class="email-dialog"
      :dialog="sendEmailDialog"
      @close="sendEmailDialog = false"
    >
      <template v-slot:header-icon>
        <img src="/images/offer/send-email.svg" alt="Send Email"/>
      </template>

      <template v-slot:header-text>
        <div class="text-center" v-balance-text>{{ $t('offer-manager.email-dialog.title') }}</div>
      </template>

      <template v-slot:body>
        <div class="text-center">
          <div v-balance-text.children v-html="$t('offer-manager.email-dialog.subtitle')"/>
          <m-text-field id="email-field" :value="offerEmail" disabled/>

          <v-btn
            class="btn-primary mt-9"
            @click="sendEmail()"
            :loading="isSendingEmail"
            :disabled="isSendingEmail"
          >
            {{ $t('offer-manager.email-dialog.button') }}
          </v-btn>
        </div>
      </template>

      <template v-slot:footer-center>
        <p class="footer-text">{{ $t('offer-manager.email-dialog.info') }}
          <RouterLink :to="{ name: 'EditOfferMotorbike', params: { id: resumeOffer.id } }">
            {{ $t('offer-manager.email-dialog.link') }}
          </RouterLink>
        </p>
      </template>
    </mapit-dialog>

    <mapit-dialog
      class="email-dialog"
      :dialog="noEmailDialog"
      @close="noEmailDialog = false"
    >
      <template v-slot:header-icon>
        <img src="/images/offer/send-email.svg" alt="Send Email"/>
      </template>

      <template v-slot:header-text>
        <div class="text-center" v-balance-text>{{ $t('offer-manager.email-dialog.title-no-email') }}</div>
      </template>

      <template v-slot:body>
        <div class="text-center">
          <div v-balance-text.children v-html="$t('offer-manager.email-dialog.subtitle-no-email')"></div>
        </div>
      </template>

      <template v-slot:footer-center>
        <v-btn
          :disabled="isEditOfferLoading"
          :loading="isEditOfferLoading"
          @click.once="goToEditOffer"
          class="btn-primary"
        >{{ $t('offer-manager.email-dialog.edit-offer') }}
        </v-btn>
      </template>
    </mapit-dialog>

    <mapit-dialog
      class="email-dialog"
      :dialog="successEmailDialog"
      @close="successEmailDialog = false"
    >
      <template v-slot:header-icon>
        <v-icon class="fa-light fa-circle-check" color="#00803F"/>
      </template>

      <template v-slot:header-text>
        <div class="text-center" v-balance-text>{{ $t('offer-manager.email-dialog.success') }}</div>
      </template>

      <template v-slot:footer-center>
        <v-btn
          class="btn-primary mt-6"
          @click="successEmailDialog = false"
        >{{ $t('offer-manager.email-dialog.close') }}
        </v-btn>
      </template>
    </mapit-dialog>

    <mapit-dialog
      class="email-dialog"
      :dialog="errorEmailDialog"
      @close="errorEmailDialog = false"
    >
      <template v-slot:header-icon>
        <v-icon class="fa-light fa-circle-xmark" color="#DC182D"/>
      </template>

      <template v-slot:header-text>
        <div class="text-center" v-balance-text>{{ $t('offer-manager.email-dialog.error') }}</div>
      </template>

      <template v-slot:body>
        <div class="text-center">
          <div v-balance-text.children v-html="$t('offer-manager.email-dialog.error-message')"/>
        </div>
      </template>

      <template v-slot:footer-center>
        <v-btn
          class="btn-primary"
          @click="errorEmailDialog = false"
        >{{ $t('offer-manager.email-dialog.close') }}
        </v-btn>
      </template>
    </mapit-dialog>
  </div>
</template>

<script>
import PdfViewer from '../../general/PdfViewer'
import {downloadFile} from '@/helpers/downloadFile'
import printJS from "print-js";
import {mapGetters} from "vuex";
import LabelTooltip from "@/components/text/LabelTooltip.vue";
import MapitDialog from "@/components/dialog/MapitDialog.vue";
import offersApi from "@/api/offers.api";
import { themes } from "@/components/atom/MChip/theme"

import { Status } from "@mapit/honda-offers-builder/dist/types";
import { RegistrationTypes } from '@/utils/constants'
import { isRegistrationAllowed } from '@/utils/registration-helper';
import { Variant } from '@/components/atom/MChip/types'
import { isExpiredOffer } from '@/utils/offers'
import MChip from '@/components/atom/MChip/index.vue'

export default {
  name: 'ResumeOffer',
  components: {
    MapitDialog,
    PdfViewer,
    LabelTooltip,
    MChip
  },
  props: ['resumeOffer', 'loading'],
  data() {
    return {
      pdfKey: 1,
      interval: null,
      loadingOfferSummary: true,
      code: null,
      isEditOfferLoading: false,
      isEditOfferDisabled: false,
      isSendingEmail: false,
      sendEmailDialog: false,
      noEmailDialog: false,
      successEmailDialog: false,
      errorEmailDialog: false,
      theme: themes.info,
      chipData: null,
      offer: null
    }
  },
  methods: {
    createRegistration() {
      this.$gtm.trackEvent({category: 'Offers', action: 'CreateRegistrationFromOffer', label: 'FromResume'})
      this.$router.push({
        name: 'HondaRegistration',
        query: {forcedType: RegistrationTypes.OFFER_REGISTRATION, offerId: this.resumeOffer.id}
      })
    },
    loadOfferSummary(id) {
      this.$store
        .dispatch('loadOfferSummaryById', {id, dealerId: this.group.dealerId})
        .then((offer) => {
          this.pdfKey++
          this.offer = offer
          this.isEditOfferDisabled = ![Status.Accepted, Status.Pending].includes(offer.status)
          this.chipData = this.statusChip(offer)
          this.interval = clearInterval(this.interval)
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.interval = clearInterval(this.interval)
            return this.$router.push({name: 'Offers'})
          }
          if (!this.interval) this.interval = setInterval(this.loadOfferSummary, 2000, id)
        })
    },
    printPdf() {
      this.$gtm.trackEvent({category: 'Offers', action: 'PrintOffer', label: 'FromResume'})
      printJS({printable: this.pdfUrl, type: 'pdf'})
    },
    downloadFile() {
      this.$gtm.trackEvent({category: 'Offers', action: 'DownloadOffer', label: 'FromResume'})
      const code = this.offerCode ?? this.resumeOffer.id
      const fileName = `${this.group.code}-${code}.pdf`
      downloadFile(this.pdfUrl, fileName)
    },
    goToEditOffer() {
      this.isEditOfferLoading = true
      this.$gtm.trackEvent({ category: 'Offers', action: 'EditOffer', label: 'FromResume' })
      this.$router.push({ name: 'EditOfferMotorbike', params: { id: this.resumeOffer.id } })
    },
    openEmailDialog() {
      if (!this.offerEmail) {
        this.sendEmailDialog = false
        this.noEmailDialog = true
        return
      }

      this.sendEmailDialog = true
      this.noEmailDialog = false
    },
    async sendEmail() {
      this.isSendingEmail = true

      this.$gtm.trackEvent({category: 'Offers', action: 'SendEmailOffer', label: 'ClickButton'})

      try {
        const response = await offersApi.sendOfferByEmail(this.group.id, this.resumeOffer.id)
        if (response.status === 200 && response.data.success === true) {
          this.$gtm.trackEvent({category: 'Offers', action: 'SendEmailOffer', label: 'OfferSent'})
          this.sendEmailDialog = false
          this.successEmailDialog = true
        } else {
          switch (response.status) {
            case 404:
              this.$gtm.trackEvent({category: 'Offers', action: 'SendEmailOffer', label: 'OfferNotFound'})
              break;
            case 403:
              this.$gtm.trackEvent({category: 'Offers', action: 'SendEmailOffer', label: 'NotAuthorized'})
              break;
            default:
              this.$gtm.trackEvent({category: 'Offers', action: 'SendEmailOffer', label: 'SendError'})
              break;
          }
          console.error(response)
          this.sendEmailDialog = false
          this.errorEmailDialog = true
        }
      } catch(e) {
        this.$gtm.trackEvent({category: 'Offers', action: 'SendEmailOffer', label: 'SendError'})
        console.error(e)
        this.sendEmailDialog = false
        this.errorEmailDialog = true
      } finally {
        this.isSendingEmail = false
      }
    },
    hasBeenResponded(offer) {
      return offer.updatedAtStatusAccepted || offer.updatedAtStatusRefused
    },
    statusChip(offer) {
      const text = this.$t(`offers.status.${offer.status}`)
      const variant = isExpiredOffer(offer) ? Variant.Disabled : undefined

      if (offer.status === Status.Pending && this.hasBeenResponded(offer)) {
        return {
          icon: 'fa-pencil',
          text,
          variant: variant || Variant.Warning,
          tooltip: this.$t(`offers.tooltip.pending-updated`),
        }
      }

      if (offer.status === Status.Pending && !this.hasBeenResponded(offer)) {
        return {
          icon: 'fa-hourglass',
          text,
          variant: variant || Variant.Warning,
        }
      }

      if (offer.status === Status.Accepted && offer.updatedStatusFromEmail) {
        return {
          icon: 'fa-envelope',
          text,
          variant: variant || Variant.Info,
          tooltip: this.$t('offers.tooltip.accepted-email'),
        }
      }

      if (offer.status === Status.Refused && offer.updatedStatusFromEmail) {
        return {
          icon: 'fa-envelope',
          text,
          variant: Variant.Error,
          tooltip: this.$t('offers.tooltip.rejected-email'),
        }
      }

      if (offer.status === Status.Refused) {
        return {
          icon: 'fa-xmark',
          text,
          variant: Variant.Error,
        }
      }

      if (offer.status === Status.Completed) {
        return {
          text,
          variant: Variant.Success,
        }
      }

      return {
        text,
        variant: variant || Variant.Info,
      }
    }
  },
  created() {
    if (!this.resumeOffer.id) {
      this.$router.push({ name: 'Offers' })
      return
    }

    this.loadingOfferSummary = true
    this.$store.dispatch('clearOfferPdf')
    this.loadOfferSummary(this.resumeOffer.id)

    this.$root.$on('pdfLoaded', () => {
      this.loadingOfferSummary = false
      this.code = this.offerCode
    })
  },
  computed: {
    ...mapGetters({
      pdfUrl: 'getPdfUrl',
      offerCode: 'getOfferCode',
      group: 'getGroup',
      offerStatus: 'getOfferStatus',
      offerEmail: 'getOfferEmail',
    }),
    canCreateRegistration() {
      return isRegistrationAllowed(this.offerStatus)
    },
    canSendEmail() {
      return this.offerStatus === Status.Pending
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval)
  },
}
</script>

<style>
#email-field {
  .v-text-field__slot input {
    text-align: center !important;
  }
}

#ResumeView {
  @media screen and (max-width: 1500px) {
    .action-wrapper {
      .label-tooltip.limit-width.inner-content {
        display: grid !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.item-title {
  color: var(--v-gray-darken4);
  text-transform: uppercase;
  font-size: 40px;
  font: normal small-caps bold 40px/50px 'ProximaNovaExCnSbold';
}

.v-card__text{
  font-size: 16px;
}

.v-chip.v-size--default{
  font-family: 'ProximaNovaSemibold';
  border-radius: 5px;
  height: 25px;
}

.offer-title {
  font-family: 'ProximaNovaExCnSbold', sans-serif !important;
  font-size: 24px !important;
  color: var(--v-primary-base);
  text-transform: uppercase !important;
}

.icon-gray {
  color: var(--v-gray-lighten5);
  font-size: 24px;
}

.label-btn-gray {
  letter-spacing: 0;
  color: var(--v-gray-lighten5);
  text-align: center;
  font: normal small-caps normal 14px/26px 'ProximaNovaRegular';
  text-transform: uppercase;
  opacity: 1;
}

.code-wrapper {
  flex-direction: column;

  & > div{
    gap: 12px;
  }
}

.action-wrapper {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
}

@media screen and (max-width: 1500px) {
  .code-wrapper {
    flex-direction: column;
  }

  .action-wrapper {
    margin-left: unset;
    width: 100%;
    max-width: 100%;
    display: grid !important;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);

    .helper-button,
    .helper-button--alone {
      margin-left: unset !important;
    }

    .label-tooltip.limit-width.inner-content {
      display: grid !important;
    }
  }

  .pdf-table-container{
    flex-direction: column;
  }
}

.helper-button,
.helper-button--alone {
  padding: 4px 12px;
  border: 1px solid #9a9a9a;

  .inner-wrapper {
    padding: 8px 22px;

    .inner-text {
      margin-left: 8px;
    }
  }

  &:not(:first-child) {
    margin-left: 16px;
  }

  &:disabled {
    background: #EDEDED;
    border: none;
  }
}

.helper-button--alone {
  margin-left: 16px;
}

.helper-button--registration{
  background-color: #00803F;
  border-color: transparent;

  .inner-wrapper {
    color: white;
  }

  &:disabled{
    .inner-wrapper {
      color: rgba(0, 0, 0, 0.26);
    }
  }
}

#email-field {
  width: 75% !important;
  margin: 6px auto 0;
}

.footer-text {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-family: "ProximaNovaRegular", sans-serif;
  margin: 0;

  a {
    color: #DC182D;
    text-decoration: underline;
  }
}

.actions-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px
}

.pdf-table-container{
  gap: 64px;

  & > div {
    width: 50%;

    @media screen and (max-width: 1500px) {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1500px) {
  .actions-wrapper {
    flex-direction: column;
  }
}

</style>
