import { render, staticRenderFns } from "./RepairShopProfile.vue?vue&type=template&id=fbd13cd2&"
import script from "./RepairShopProfile.vue?vue&type=script&lang=ts&"
export * from "./RepairShopProfile.vue?vue&type=script&lang=ts&"
import style0 from "./RepairShopProfile.vue?vue&type=style&index=0&global=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VBtn,VCol,VDatePicker,VExpansionPanels,VForm})
