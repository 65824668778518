<template>
  <div>
    <v-card-text class="label-init-search pt-8 pb-0">
      <contact-lookup
        v-model="contact"
        :text="$t('offer-manager.create.lbl-init-search')"
        :message-not-found="$t('offer-manager.create.init.not-found')"
        :message-error="$t('offer-manager.create.init.error')"
        @search="onContactSearched"
        :country="country"
      />
    </v-card-text>

    <v-card-actions class="px-6 pt-12 pb-10 d-flex justify-space-between">
      <v-btn
        ref="nextBtn"
        v-if="phoneSearched"
        class="btn-primary ml-auto"
        data-cy="offer-manager-create-button-continue"
        @click="goToCreateOfferMotorbike"
      >
        {{ $t('buttons.next') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import router from '@/router'
import ContactLookup from '@/components/contact/ContactLookup'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'InitOffer',
  components: { ContactLookup },
  props: [],
  data() {
    return {
      loading: false,
      phoneSearched: null,
    }
  },
  mounted() {
    this.$store.dispatch('clearOffer')
  },
  computed: {
    ...mapState(['permissions']),
    ...mapGetters({
      country: 'getCountry',
    }),
    contact: {
      get() {
        return this.$store.getters.getOfferSearchedContact
      },
      set(contact) {
        this.$store.commit('SET_OFFER_SEARCHED_CONTACT', contact)
      },
    },
  },
  methods: {
    ...mapActions(['showAlertSuccess', 'sendGAEvent']),
    async goToCreateOfferMotorbike() {
      if (!this.contact) {
        this.contact = {
          phone: this.phoneSearched,
        }
        console.log('contact', this.phoneSearched)
      }
      this.sendGAEvent('InitOffer', 'Steps')
      await router.push({ name: 'CreateOfferMotorbike' })
    },
    async onContactSearched(phone, contact) {
      if (contact) {
        await this.showAlertSuccess('offer-manager.create.init.found')
        await this.goToCreateOfferMotorbike()
      } else {
        this.phoneSearched = phone
        this.$nextTick(() => this.$refs.nextBtn?.$el.focus())
      }
    },
    async createSubagentOffer() {
      this.$router.push({ name: 'CreateOfferMotorbike', query: { isSubagent: true } })
    },
    async sendGAEvent(action, label) {
      await this.$gtm.trackEvent({ category: 'Offers', action, label })
    },
  },
}
</script>
<style lang="scss" scoped>
.init-image-header {
  max-width: 160px;
  margin: auto;
  text-align: center;
}

.label-init-search {
  text-align: center;
  font: normal normal normal 16px/26px 'ProximaNovaRegular';
  letter-spacing: 0px;
  color: var(--v-gray-darken4);
  opacity: 1;
}

.subagent-link {
  text-transform: inherit;
}
</style>
