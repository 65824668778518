<template>
  <div>
      <v-row>
        <v-col class="col-5 col-md-5 col-sm-12 bold-900">
          MOTIVO DE LA CITA
        </v-col>
        <v-col class='col-7 col-md-7 col-sm-12'>
          <v-row no-gutters>
            <v-col cols="6">
              <label class="form-checkbox">
                <input type="checkbox" v-model="requested.service" @input="modified=!modified" /><i class="form-icon"></i> Revisión
              </label>
            </v-col>
            <v-col cols="6">
              <label class="form-checkbox">
                <input type="checkbox" v-model="requested.tyres" /><i class="form-icon"></i> Cambio de Neumáticos
              </label>
            </v-col>
            <v-col cols="6">
              <label class="form-checkbox">
                <input type="checkbox" v-model="requested.failure" /><i class="form-icon"></i> Reparación mecánica
              </label>
            </v-col>
            <v-col cols="6">
              <label class="form-checkbox">
                <input type="checkbox" v-model="requested.paint" /><i class="form-icon"></i> Reparación chapa y pintura
              </label>
            </v-col>
            <v-col cols="12" class="d-flex flex-column">
              <label class="form-checkbox">
                <input type="checkbox" v-model="isChecked" data-cy="other-check" /><i class="form-icon"></i> Otras reparaciones{{isChecked ? ':' : ""}}
              </label>
              <m-textarea v-model="localOther"
                          v-if="isChecked"
                          data-cy="other-comment"
                          placeholder="Otro motivo"
                          rows="1"
                          auto-grow
                          solo/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class='col-12' v-if="showWarning && modified && wasRevisionChecked">
        <div class="modified-alert">
          <i class="fa fa-exclamation-circle pr-2"/>Si desmarca la opción 'Revisión', el kilometraje en Honda Mapit no se actualizará
        </div>
      </div>
  </div>
</template>

<script>
import Vue from 'vue';

  export default {
    props: {
      requested: Object,
      showWarning: {
        type: Boolean,
        default: () => false
      }
    },
    computed: {
      localOther: {
        get() {
          if (this.isChecked) {
            return this.requested.other || "";
          }
        },
        set(val) {
          if(val && val.length>0) {
            this.requested.other = val;
            this.isChecked=true;
          }else{
            this.requested.other = false;
            this.isChecked=false;
            this.$emit("input", this.requested);
          }
        }
      }
    },
    methods: {
      computeOther(e) {
        if(!e.target.checked) {
          this.requested.other = false;
          this.localOther = "";
          this.$emit("input", this.requested);
        }
      }
    },
    data() {
      return {
        isChecked: this.requested.other && this.requested.other.length>0,
        modified: false,
        wasRevisionChecked: this.requested.service
      }
    }
  }
</script>
<style scoped>

  .redbg {
    color: red !important;
  }

  .selectedBold{
    font-weight: bold;
  }

</style>
