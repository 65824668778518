<template>
  <th class="align-center" v-if="isHeader">
    {{ title }}
  </th>
  <button
    v-else-if="rowData"
    data-cy="repair-btn"
    class="btn btn-honda-plus-light-mini btn-radius"
    @click="$emit('click-field-event', { payload: rowData })"
  >
    <i class="fas fa-wrench"></i>
  </button>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'

export default {
  mixins: [VuetableFieldMixin],
  props: ['rowData'],
  name: 'Repair',
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>
