import Container from '@/components/tablecolumns/Container.vue';
import Client from '@/components/tablecolumns/Client.vue';
import AppointmentStatus from '@/components/tablecolumns/appointment/Status.vue';
import AppointmentButton from '@/components/tablecolumns/appointment/Button.vue';
import AppointmentDay from '@/components/tablecolumns/appointment/Day.vue';
import AppointmentReason from '@/components/tablecolumns/appointment/Reason.vue';
import AppointmentTime from '@/components/tablecolumns/appointment/Time.vue';
import AppointmentHasContainer from '@/components/tablecolumns/appointment/HasContainer.vue';
import Repair from '@/components/tablecolumns/repair/Repair.vue';
import RepairPickupDay from '@/components/tablecolumns/repair/Day.vue';
import RepairPickupTime from '@/components/tablecolumns/repair/Time.vue';
import Plate from '@/components/tablecolumns/Plate.vue';
import Model from '@/components/tablecolumns/Model.vue';
import KmNextRevision from '@/components/tablecolumns/nextrevision/KmNextRevision.vue';

export const hashMap = {
    'container': Container,
    'client': Client,
    'appointmentStatus': AppointmentStatus,
    'appointmentButton': AppointmentButton,
    'appointmentDay': AppointmentDay,
    'appointmentReason': AppointmentReason,
    'appointmentTime': AppointmentTime,
    'appointmentHasContainer': AppointmentHasContainer,
    'repair': Repair,
    'pickupDay': RepairPickupDay,
    'pickupTime': RepairPickupTime,
    'plate': Plate,
    'model': Model,
    'kmnextrevision': KmNextRevision,
}

export default (key: keyof typeof hashMap) => {
    return hashMap[key];
};
