































































import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'
import { DateTime } from 'luxon'

import { CoreDataApi } from '@/api/core-data.api'
import { RepairOrdersApi } from '@/api/repairOrders.api'
import { mapGetters } from '@/utils/map-store'
import { useDealerStats } from '@/composables'

import {
  StatsLeadsResponse,
  StatsDemoBikesResponse,
  StatsOffersResponse,
  StatsRegistrationsResponse,
  StatsSalesResponse,
  StatsAppointmentsResponse,
} from '@/types'

import {
  mapAppointmentsData,
  mapDemoBikesData,
  mapLeadsData,
  mapOffersData,
  mapPreDeliveriesData,
  mapRegistrationsData,
} from '@/mappers/dealers'

import StatsLeads from '@/components/metrics/StatsLeads.vue'
import StatsDemoBikes from '@/components/metrics/StatsDemoBikes.vue'
import StatsOffers from '@/components/metrics/StatsOffers.vue'
import StatsRegistrations from '@/components/metrics/StatsRegistrations.vue'
import StatsPreDeliveries from '@/components/metrics/StatsPreDeliveries.vue'
import StatsAppointments from '@/components/metrics/StatsAppointments.vue'

export default defineComponent({
  name: 'DealerDashboard',
  components: {
    StatsLeads,
    StatsDemoBikes,
    StatsOffers,
    StatsRegistrations,
    StatsPreDeliveries,
    StatsAppointments,
  },
  setup(props, { root }) {
    const coreDataApi = new CoreDataApi()
    const repairOrdersApi = new RepairOrdersApi()
    const {
      getBranch: branch,
      getCapabilities: capabilities,
      getDealerCreatedAt,
      getDealerId,
      getLocale: locale,
      getPermissions: permissions,
      getUserGroups: roles,
    } = mapGetters(root.$store.getters)

    const isHondaPlusSupported =
      capabilities.value?.hondaPlus?.supported ??
      root.$preferences(branch.value).hondaPlus.supported

    const isAppointmentsSupported =
      capabilities.value?.maintenance?.supported ??
      capabilities.value?.maintenance ??
      root.$preferences(branch.value).maintenance.supported

    const month = ref(DateTime.now().toFormat('yyyy-MM'))
    const today = computed(() => DateTime.now().toFormat('yyyy-MM'))
    const minDate = computed(() => DateTime.fromISO(getDealerCreatedAt.value).toFormat('yyyy-MM'))

    const selectedMonth = computed(() => {
      return DateTime.fromFormat(month.value, 'yyyy-MM').month
    })

    const selectedYear = computed(() => {
      return DateTime.fromFormat(month.value, 'yyyy-MM').year
    })

    const {
      stats: statsLeads,
      loading: isLeadsLoading,
      refetch: fetchLeadsStats,
    } = useDealerStats<StatsLeadsResponse>({
      selectedMonth,
      selectedYear,
      onFetchData: onLeadsFetchData,
      onStatsRender: mapLeadsData,
    })

    const {
      stats: statsDemoBikes,
      loading: isDemoBikesLoading,
      refetch: fetchDemoBikesStats,
    } = useDealerStats<StatsDemoBikesResponse>({
      selectedMonth,
      selectedYear,
      onFetchData: onDemoBikesFetchData,
      onStatsRender: (data) => mapDemoBikesData(data, roles.value),
    })

    const {
      stats: statsOffers,
      loading: isOffersLoading,
      refetch: fetchOffersStats,
    } = useDealerStats<StatsOffersResponse>({
      selectedMonth,
      selectedYear,
      onFetchData: onOffersFetchData,
      onStatsRender: mapOffersData,
    })

    const {
      stats: statsRegistrations,
      loading: isRegistrationsLoading,
      refetch: fetchRegistrationsStats,
    } = useDealerStats<StatsRegistrationsResponse>({
      selectedMonth,
      selectedYear,
      onFetchData: onRegistrationsFetchData,
      onStatsRender: mapRegistrationsData,
    })

    const {
      stats: statsPreDeliveries,
      loading: isPreDeliveriesLoading,
      refetch: fetchPreDeliveriesStats,
    } = useDealerStats<StatsSalesResponse>({
      selectedMonth,
      selectedYear,
      onFetchData: onPreDeliveriesFetchData,
      onStatsRender: mapPreDeliveriesData,
    })

    const {
      stats: statsAppointments,
      loading: isAppointmentsLoading,
      refetch: fetchAppointmentsStats,
    } = useDealerStats<StatsAppointmentsResponse>({
      selectedMonth,
      selectedYear,
      onFetchData: onAppointmentsFetchData,
      onStatsRender: mapAppointmentsData,
    })

    onMounted(async () => {
      Promise.all([
        fetchLeadsStats(),
        fetchDemoBikesStats(),
        fetchOffersStats(),
        fetchRegistrationsStats(),
        fetchPreDeliveriesStats(),
        fetchAppointmentsStats(),
      ])
    })

    watch(month, () => {
      fetchLeadsStats()
      fetchDemoBikesStats()
      fetchOffersStats()
      fetchRegistrationsStats()
      fetchPreDeliveriesStats()
      fetchAppointmentsStats()
    })

    async function onLeadsFetchData() {
      return await coreDataApi.getDealerStats<StatsLeadsResponse>(
        getDealerId.value,
        'leads',
        selectedYear.value,
        selectedMonth.value,
      )
    }

    async function onDemoBikesFetchData() {
      return await coreDataApi.getDealerStats<StatsDemoBikesResponse>(
        getDealerId.value,
        'demo-bikes',
        selectedYear.value,
        selectedMonth.value,
      )
    }

    async function onOffersFetchData() {
      return await coreDataApi.getDealerStats<StatsOffersResponse>(
        getDealerId.value,
        'offers',
        selectedYear.value,
        selectedMonth.value,
      )
    }

    async function onRegistrationsFetchData() {
      return await coreDataApi.getDealerStats<StatsRegistrationsResponse>(
        getDealerId.value,
        'honda-plus',
        selectedYear.value,
        selectedMonth.value,
      )
    }

    async function onPreDeliveriesFetchData() {
      return await coreDataApi.getDealerStats<StatsSalesResponse>(
        getDealerId.value,
        'sales',
        selectedYear.value,
        selectedMonth.value,
      )
    }

    async function onAppointmentsFetchData() {
      const res = await repairOrdersApi.getAppointmentStatsPerMonth(
        getDealerId.value,
        selectedYear.value,
        selectedMonth.value,
      )

      return [res]
    }

    const categories = {
      leads: {
        title: 'honda.dashboard.stats.leads.title',
        tooltip: 'honda.dashboard.stats.leads.tooltip',
        permission: permissions.value.dealerDashboard?.canSee,
      },
      demoBikes: {
        title: 'honda.dashboard.stats.demo-bikes.title',
        tooltip: 'honda.dashboard.stats.demo-bikes.tooltip',
        permission: permissions.value.dealerBikes.canSee,
        sectionLoansSales: permissions.value.sectionLoansSales?.canSee,
      },
      offers: {
        title: 'honda.dashboard.stats.offers.title',
        tooltip: 'honda.dashboard.stats.offers.tooltip',
        permission: permissions.value.dealerDashboard?.canSee,
      },
      registrations: {
        title: 'honda.dashboard.stats.registrations.title',
        tooltip: 'honda.dashboard.stats.registrations.tooltip',
        permission: permissions.value.dealerDashboard?.canSee && isHondaPlusSupported,
      },
      preDeliveries: {
        title: 'honda.dashboard.stats.pre-deliveries.title',
        tooltip: 'honda.dashboard.stats.pre-deliveries.tooltip',
        permission: permissions.value.dealerDashboard?.canSee,
      },
      appointments: {
        title: 'honda.dashboard.stats.appointments.title',
        tooltip: 'honda.dashboard.stats.appointments.tooltip',
        permission: permissions.value.appointments?.canSee && isAppointmentsSupported,
      },
    }

    return {
      categories,
      isAppointmentsLoading,
      isDemoBikesLoading,
      isLeadsLoading,
      isOffersLoading,
      isPreDeliveriesLoading,
      isRegistrationsLoading,
      locale,
      minDate,
      month,
      selectedMonth,
      statsAppointments: statsAppointments as any,
      statsDemoBikes: statsDemoBikes as any,
      statsLeads: statsLeads as any,
      statsOffers: statsOffers as any,
      statsPreDeliveries: statsPreDeliveries as any,
      statsRegistrations: statsRegistrations as any,
      today,
    }
  },
})
