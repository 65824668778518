<template>
  <th class='align-center' v-if="isHeader">
    {{ title }}
  </th>
  <div v-else-if="rowData" class="col-12 p-l-15 p-r-15">
    <span class="f-s-15">
      <button class='btn btn-honda-plus-light btn-sm btn-radius' @click="$emit('click-field-event', { payload: rowData })">
        Gestionar
      </button>
    </span><br />
  </div>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';


export default {
  mixins: [VuetableFieldMixin],
  props: ["rowData"],
  name: 'Button'
}
</script>
