<template id="repair-modal">
  <modal :active="active" @close="closeModal" :title="title">
    <template slot="body">
      <customer-info v-if="mStep==0" :customer='customer' :container='container'></customer-info>
      <repair-detail  v-if="mStep==0" :request="cloned" :container="container" :inspection="inspection" @accept="passRepair" @reject="rejectRepair"/>
      <repair-confirm v-else-if="mStep==1"/>
      <repair-delete-confirm v-else-if="mStep==3" @confirm="confirmDeleteRepair"></repair-delete-confirm>
      <repair-delete-message  v-else-if="mStep==2"  @close="closeDeleteMessage"  @redirect="goToAppointment"></repair-delete-message>
      <request-modified v-if="mStep==4" />
      <error-message v-if="mStep==5" />
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal';
import RepairDetail from './RepairDetail.vue';
import RepairConfirm from './RepairConfirm.vue';
import RepairDeleteConfirm from './RepairDeleteConfirm';
import RepairDeleteMessage from '@/components/dialog/RepairDeleteMessage';
import { eventBus } from "@/main";
import CustomerInfo from '@/components/dialog/CustomerInfo';
import RequestModified from '@/components/dialog/RequestModified';
import ErrorMessage from '@/components/dialog/ErrorMessage';
import repairOrdersApi from '@/api/repairOrders.api';

export default {
  props: {
    active: { type: Boolean, default: false },
    inspection: { type: Object, default: null },
    request: { type: Object, default: null },
    container: { type: Object, default: null },
    customer: { type: Object, default: null },
    step: { type: Number, default: 0 }
  },
  components: {
    Modal,
    RepairDetail,
    // RepairDelete,
    RepairConfirm,
    RepairDeleteConfirm,
    CustomerInfo,
    RequestModified,
    ErrorMessage,
    RepairDeleteMessage
  },
  template: "#repair-modal",
  data() {
    const location = { kind: '', description: '' };
    const currentRevision = { km: null, day: new Date() };
    const amount = 0;
    
    let cloned = JSON.parse(JSON.stringify({ location, currentRevision, amount, ...this.request }));
    cloned.pickup = cloned.pickup || { day: null, hour: null };
    cloned.notifyUser = false;

    return {
      mStep: this.step,
      deleteRevision: { kind: 'NO_CLIENT', comment: null, nextService: null },
      cloned
    };
  },
  computed: {
    title() {
      switch (this.mStep) {
        case 0: return "DETALLE DE LA REPARACIÓN";
        case 1: return "";
        case 2: return "ELIMINAR REPARACIÓN";
        default: return "";
      }
    },
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    closeModal() {
      eventBus.$emit("refresh-repair-list");
      this.emitClose();
    },
    emitClose() {
      eventBus.$emit("refresh-counters");
      this.$emit('close');
    },
    goToAppointment() {
      this.$router.push(`/appointments/${this.request.appointment.id}`);
    },
    filterTrueKeys(input) {
      return Object.entries(input)
        .filter(([_, value]) => value)
        .map(([key]) => key.toUpperCase());
    },
    
    buildBody() {
      const { requested, notifyUser, location, pickup } = this.cloned;
      const reason = this.filterTrueKeys(requested);
    
      let body = {
        status: 'COMPLETED',
        notifyCustomer: notifyUser,
        reason
      };

      if (requested.other) {
        body.otherReason = requested.other;

        if(!reason.includes('OTHER')) {
          reason.push('OTHER');
        }
      }

      if (location?.kind) {
        body.location = {
          type: location.kind,
          description: location.kind === "OTHER" ? location.description : ''
        };
      }

      if (pickup.day) body.pickupDate = pickup.day;
      if (pickup.hour) body.pickupHour = pickup.hour;
      if (this.cloned.price > 0) body.amount = Number(this.cloned.price);

      return body;
    },

    async passRepair() {
      const id = this.request.repairOrder.id;
      const nextService = {
        km: this.cloned.currentRevision.km
          ? Number(this.cloned.currentRevision.km)
          : this.inspection?.lastService
          ? this.inspection.lastService + 1
          : 0
      };

      const repairOrderData = {
        ...this.buildBody(),
        nextService
      };

      let step = 1;
      await repairOrdersApi.completeRepairOrder(id, repairOrderData).then(
        ).catch(
          (err) => {
          if (err) {
            step = 4
          }
        })
        this.mStep = step
    },

    rejectRepair() {
      this.mStep = 2;
    },
    closeDeleteMessage() {
      this.closeModal();
    },
    confirmDeleteRepair() {
      this.mStep = 4;
      this.closeModal();
    },

    deleteRepair() {
      this.mStep = 3;
    }
  }
};
</script>


