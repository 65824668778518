<template>
  <th class="align-left" v-if="isHeader">
    {{ title }}
  </th>
  <div v-else-if="rowData">
    <span :class="getCssClasses(rowData.request)">
      <b>{{ getStatusString(rowData.request) }}</b>
    </span><br />
  </div>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

const statusMap = {
  'PENDING': {
    text: 'Solicitada', css: 'orangebg'
  },
  'ACCEPTED': {
    text: 'Aceptada', css: 'greenbg'
  },
  'REJECTED': {
    text: 'Cancelada', css: 'redbg'
  },
  'CANCELLED_ADMIN': {
    text: 'Cancelada', css: 'redbg'
  },
  'CANCELLED_USER': {
    text: 'Cancelada', css: 'redbg'
  }
};

export default {
  mixins: [VuetableFieldMixin],
  props: {
    rowData: {
      type: Object,
      default: () => {
        return { request: { status: '-' } };
      }
    }
  },
  methods: {
    getStatusString: (request) => {
      let value = ''
      if (request && request.status) {
        value = statusMap[request.status] ? statusMap[request.status].text : '';
      }
      return value;
    },
    getCssClasses: (request) => {
      let value = '';
      if (request && request.status) {
        value = statusMap[request.status] ? statusMap[request.status].css : '';
      }
      return 'f-s-15 uppercase ' + value;
    }
  },
  name: 'Status'
}
</script>

<style scoped>
.uppercase {
  text-transform: uppercase;
}

.greenbg {
  color: green !important;
}

.redbg {
  color: red !important;
}

.orangebg {
  color: orange !important;
}
</style>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>
