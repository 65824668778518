var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"d-flex",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-md-6 order-2 order-lg-0 col-lg-4 col-12 mb-2 pr-0 pr-md-2"},[_c('m-select',{attrs:{"clearable":true,"items":_vm.filterStatus,"multiple":true,"placeholder":_vm.$t('offer-manager.offers-list.lbl-filter-status'),"showSelectedCounter":true,"data-cy":"offerStatusFilter"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('i',{staticClass:"icon-mapit icon-filter-status"})]},proxy:true}]),model:{value:(_vm.queryParams.statuses),callback:function ($$v) {_vm.$set(_vm.queryParams, "statuses", $$v)},expression:"queryParams.statuses"}})],1),_c('v-col',{staticClass:"col-md-6 order-2 order-lg-0 col-lg-4 col-12 d-flex align-center mb-2 pr-0 pr-md-2"},[_c('v-checkbox',{staticClass:"m-checkbox",attrs:{"label":_vm.$t('offers.filters.expired-offers')},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1),_c('v-col',{staticClass:"col-md-6 order-2 order-lg-0 col-lg-4 col-12 mb-2 pr-0 pr-md-2"},[_c('m-text-field',{attrs:{"clearable":true,"placeholder":_vm.$t('offer-manager.offers-list.lbl-search'),"data-cy":"offerSearchFilter"},on:{"keydown":_vm.onTextKeydown,"keyup":_vm.onTextKeyup},model:{value:(_vm.text),callback:function ($$v) {_vm.text=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"text"}})],1)],1),_c('v-data-table',{staticClass:"elevation-0 pt-4",attrs:{"id":"offers-table-list","footer-props":{
      showFirstLastPage: true,
      firstIcon: 'fas fa-angle-double-left',
      lastIcon: 'fas fa-angle-double-right',
      prevIcon: 'fas fa-angle-left',
      nextIcon: 'fas fa-angle-right',
      'items-per-page-text': _vm.$t('offer-manager.offers-list.footer-num-x-page'),
      'items-per-page-options': [10, 25, 50],
    },"headers":_vm.headers,"item-class":_vm.getRowClass,"items":_vm.offerList,"items-per-page":_vm.tablePagination.itemsPerPage,"loading":_vm.loading || _vm.offerListLoading,"loading-text":_vm.$t('honda.offers.list.loading'),"no-data-text":_vm.$t(
        _vm.offerList && _vm.offerList.length === 0 && _vm.text
          ? 'offer-manager.offers-list.no-data-search'
          : 'offer-manager.offers-list.no-data',
        { search: _vm.text }
      ),"no-results-text":_vm.$t('offer-manager.offers-list.no-data-search'),"page":_vm.tablePagination.page,"server-items-length":_vm.total,"sort-by":_vm.sortBy.column,"sort-desc":_vm.sortBy.sort === 'desc'},on:{"click:row":_vm.viewOfferSummary,"update:items-per-page":_vm.onItemsPerPageChange,"update:options":_vm.onSortByChange,"update:page":_vm.onPageChange},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
      var pageStart = ref.pageStart;
      var pageStop = ref.pageStop;
      var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" "+_vm._s(_vm.$t('honda.registration.list.of'))+" "+_vm._s(itemsLength)+" ")]}},{key:"item.createDate",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-item"},[_vm._v(_vm._s(_vm._f("formatDate")(item.createDate)))])]}},{key:"item.client",fn:function(ref){
      var item = ref.item;
return [(item.type !== _vm.OfferType.subagent)?_c('m-copy',{attrs:{"text-to-copy":((_vm.getFullName(item)) + ", " + (item.client.email) + ", " + (item.client.phone))},on:{"onCopy":_vm.onCopyClick}},[_c('label-tooltip',[_c('div',{staticClass:"customer text-left py-1"},[_c('span',{staticClass:"customer-header"},[_vm._v(_vm._s(_vm.getFullName(item)))]),_c('br'),(item.client.email)?_c('span',{staticClass:"customer-email"},[_vm._v(_vm._s(item.client.email)),_c('br')]):_vm._e(),(item.client.phone)?_c('m-phone-label',{staticClass:"customer-phone",attrs:{"value":item.client.phone}}):_vm._e()],1)])],1):_c('div',{staticClass:"customer py-3 px-2 px-md-0"},[_c('p',{staticClass:"customer-header"},[_vm._v(_vm._s(_vm.$t('offer-manager.offers-list.lbl-subagent')))])])]}},{key:"item.vehicle.model",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"#222","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"customer py-3 px-2 px-md-0"},'div',attrs,false),on),[_c('p',{staticClass:"customer-header"},[_vm._v(_vm._s(_vm.getModel(item.vehicle.model.name)))])])]}}],null,true)},[_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.vehicle.model.name))]),(item.vehicle.model.subname)?_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.vehicle.model.subname))]):_vm._e()])])]}},{key:"item.offerCode",fn:function(ref){
      var item = ref.item;
return [(item.status !== _vm.Status.Pending && item.status !== _vm.Status.Refused)?_c('m-copy',{attrs:{"text-to-copy":item.offerCode,"align":"center"},on:{"onCopy":_vm.onCopyClick}},[_c('div',{staticClass:"text-item"},[_vm._v(_vm._s(item.offerCode))])]):_vm._e()]}},{key:"item.attendedBy",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-item"},[_vm._v(_vm._s(item.attendedBy))])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return _vm._l(([_vm.statusChip(item)]),function(ref,index){
      var icon = ref.icon;
      var text = ref.text;
      var variant = ref.variant;
      var tooltip = ref.tooltip;
return _c('m-chip',{key:index,attrs:{"icon":icon,"text":text,"tooltip":tooltip,"variant":variant}})})}},{key:"item.followUp",fn:function(ref){
      var item = ref.item;
return [(item.expired)?_c('v-tooltip',{attrs:{"max-width":300,"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('p',_vm._g({staticClass:"text-expired"},on),[_vm._v(" "+_vm._s(_vm.$t('offers.filters.expired'))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('offers.tooltip.expired')))])]):(item.followUp)?_c('v-tooltip',{attrs:{"max-width":300,"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({class:("" + (_vm.isPastToday(item.followUp.dueDate) ? 'text-expired' : ''))},on),[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.followUp.dueDate))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.followUp.text))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-mapit notranslatable",attrs:{"data-cy":("menu-" + (item.id))}},[_vm._v(")")])],1)]}}],null,true)},[_c('v-list',{staticClass:"list-menu-actions",attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.viewOfferSummary(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('offers.actions.view'))+" ")])],1),_c('v-list-item',{attrs:{"disabled":!_vm.canEditOffer(item.status)},on:{"click":function($event){return _vm.editOffer(item.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('offers.actions.edit'))+" ")])],1),_c('v-list-item',{attrs:{"disabled":!_vm.canCreateRegistration(item.status)},on:{"click":function($event){return _vm.createRegistration(item.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('offers.actions.register'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.printPdf(item.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('offers.actions.print'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.downloadPdf(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('offers.actions.downloadPDF'))+" ")])],1),_c('v-divider'),_c('v-list-item',{attrs:{"disabled":!_vm.canChangeToAccepted(item.status)},on:{"click":function($event){return _vm.changeStatus(item, _vm.Status.Accepted)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('offers.actions.accept'))+" ")])],1),_c('v-list-item',{attrs:{"disabled":!_vm.canChangeToRefused(item.status)},on:{"click":function($event){return _vm.changeStatus(item, _vm.Status.Refused)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('offers.actions.refuse'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }