<template>
  <div>
      <resume-offer :resumeOffer="resumeOffer" :loading="resumeLoading">
        <template v-slot:extra-content>
          <dealer-notes :offerId="offerId" :panelHideDivider="true" @setLoading="setLoading"/>
        </template>
      </resume-offer>
    <v-card-actions class="px-6 pt-10 pb-6 d-flex justify-end">
      <v-btn
        class="btn-primary"
        data-cy="offer-manager-create-button-continue"
        :disabled="false"
        @click="finish"
      >
        {{ $t('buttons.finish') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import DealerNotes from './sections/DealerNotes.vue'
import ResumeOffer from './sections/ResumeOffer.vue'

export default {
  name: 'ResumeOfferMotorbike',
  components: {
    ResumeOffer,
    DealerNotes
  },
  props: [
    'leadId'
  ],
  data() {
    return {
      panels: [0, 1],
      resumeLoading: false
    }
  },
  computed: {
    resumeOffer() {
      return { id: this.$route.params.id }
    },
    offerId() {
      return this.$route.params.id
    },
  },
  methods: {
    finish() {
      if(this.leadId) {
        this.$router.push({ name: 'Leads' })
      } else {
        this.$router.push({ name: 'Offers' })
      }
    },
    setLoading(loading){
      this.resumeLoading = loading
    },
  }
}
</script>
