import { isExpiredBike } from '@/utils/dealer-bikes'
import {
  DealerBike,
  DealerBikeActions,
  DealerBikeStatus,
  DealerBikeStatusPortal,
  UsageType,
} from '@/types/dealer-bikes'

interface ItemData {
  dealerBike: DealerBike
  status?: DealerBikeStatus
  usageType?: UsageType
}

interface CollectionData {
  data: DealerBike[]
  id: string
  status?: DealerBikeStatus
  usageType?: UsageType
}

export function mapDealerBikesCollection({ data, id, status, usageType }: CollectionData) {
  return data.map((item: DealerBike) => {
    const payload: ItemData =
      item.id === id && (status || usageType)
        ? { dealerBike: item, status, usageType }
        : { dealerBike: item }

    return mapDealerBike(payload)
  })
}

export function mapDealerBike({ dealerBike, status, usageType }: ItemData) {
  let dealerBikeMapped: any = dealerBike

  if (status && status !== dealerBike.status) {
    dealerBikeMapped = { ...dealerBikeMapped, status }
  }

  if (usageType && usageType !== dealerBike.usageType) {
    dealerBikeMapped = { ...dealerBikeMapped, usageType }
  }

  if (
    [DealerBikeStatus.Available, DealerBikeStatus.Unavailable].includes(dealerBike.status) &&
    dealerBike.usageType === UsageType.Demo &&
    isExpiredBike(dealerBike.createdAt as any)
  ) {
    dealerBikeMapped = { ...dealerBikeMapped, status: DealerBikeStatusPortal.Expired }
  }

  if (dealerBike.status !== DealerBikeStatus.Retired && dealerBike.loan?.id) {
    dealerBikeMapped = { ...dealerBikeMapped, status: DealerBikeStatusPortal.HandedOver }
  }

  return {
    ...dealerBikeMapped,
    statusColor: mapStatusColorFromStatus(dealerBikeMapped.status),
  }
}

export function mapDealerBikeActionToStoreAction(action: string) {
  if (action === DealerBikeActions.Available) {
    return 'activateDealerBike'
  }

  if (action === DealerBikeActions.Unavailable) {
    return 'unavailDealerBike'
  }

  if (action === DealerBikeActions.Courtesy) {
    return 'changeDealerBikeToCourtesy'
  }

  if (action === DealerBikeActions.Unregister) {
    return 'retireDealerBike'
  }

  if (action === DealerBikeActions.RemoveBike) {
    return 'deleteDealerBike'
  }

  return 'loadDealerBikeList'
}

export function mapStatusColorFromStatus(status: string) {
  if (status === DealerBikeStatusPortal.Candidate) {
    return '#3797f0' // blue
  }

  if (status === DealerBikeStatusPortal.Available) {
    return '#00803e' // green
  }

  if (status === DealerBikeStatusPortal.Unavailable || status === DealerBikeStatusPortal.Expired) {
    return '#dc182d' // red
  }

  if (status === DealerBikeStatusPortal.Retired) {
    return '#888' // gray
  }

  return '#fca400' // yellow
}

export function mapLoansToClientHistory(data: any) {
  return data.map((item: any) => ({
    ...item,
    createDate: item.createdAt,
    attendedBy: (item.return ? item.return.attendedBy || item.createdBy : item.handover? item.handover.attendedBy || item.createdBy : item.createdBy).slice(0, (item.return ? item.return.attendedBy || item.createdBy : item.handover? item.handover.attendedBy || item.createdBy : item.createdBy).indexOf('<')),
    event: 'loan',
    model: item.dealerBike.vehicle.model,
  }))
}
