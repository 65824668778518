// @ts-ignore
import ApiGatewayClient from './aws/aws-gateway-client'
import {Contact} from "@mapit/contacts-dynamodb/dist/types"
import {AxiosResponse} from "axios"

export class ContactsApi {
  private readonly client: ApiGatewayClient

  constructor() {
    this.client = new ApiGatewayClient()
  }

  getContactByEmail(email: string): Promise<AxiosResponse<{data: Contact[]}>> {
    return this.client.sendRequest({
        endpoint: process.env.VUE_APP_CONTACTS_API,
        method: 'GET',
        path: `/v1/contacts`,
        queryParams: {
            email
          }
      })
  }

  getContactByPhone(phone: string): Promise<AxiosResponse<{data: Contact[]}>> {
    return this.client.sendRequest({
        endpoint: process.env.VUE_APP_CONTACTS_API,
        method: 'GET',
        path: `/v1/contacts`,
        queryParams: {
            phone
          }
      })
  }

  getContactById(id: string): Promise<AxiosResponse<{data: Contact[]}>> {
    return this.client.sendRequest({
        endpoint: process.env.VUE_APP_CONTACTS_API,
        method: 'GET',
        path: `/v1/contacts/${id}`,
    })
  }
}


export default new ContactsApi()
