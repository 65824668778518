<template>
  <div class="supervisors">
    <div v-if="loadingAccountId || loadingAccount" class="data-card">
      <p>{{ $t('users.loading.page') }}</p>
      <v-skeleton-loader type="text" />
    </div>

    <template v-else>
      <user-detail :user="account" />
      <containers-list :vehicles="vehicles" />
    </template>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from '@vue/composition-api'
import api from '@/api'
import coreApi from '@/api/core.api'

import ContainersList from '@/components/users/ContainersList'
import UserDetail from '@/components/users/UserDetail'

export default defineComponent({
  name: 'User',
  components: {
    ContainersList,
    UserDetail,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  setup(props, { root }) {
    const error = ref(false)

    const loadingAccountId = ref(true)
    const loadingAccount = ref(true)

    const accountId = ref()
    const account = ref()
    const vehicles = ref([])

    async function fetchSupervisors(userId) {
      loadingAccountId.value = true
      error.value = false

      try {
        api.getSupervisors({ userId }, (err, res) => {
          console.log('response', res);
          console.log('accountId.value', accountId.value);
          accountId.value = res[0].accountId
          console.log('accountId.value UPDATE', accountId.value);
        })
      } catch (e) {
        console.error(error)
        error.value = true
      }

      loadingAccountId.value = false
    }

    async function fetchUserDetails(accountId) {
      loadingAccount.value = true
      error.value = false

      try {
        const response = await coreApi.getAccountSummary(accountId, { forceVehicles: true })
        const { data } = response

        account.value = data.account
        vehicles.value = data.vehicles.filter((vehicle) => vehicle.id)
      } catch (error) {
        console.error(error)
        error.value = true
      }

      loadingAccount.value = false
    }

    onMounted(async () => {
      await fetchSupervisors(props.userId)
    })

    watch(accountId, async (newId, oldId) => {
      console.log('nodoy111', );
      if (newId !== oldId) {
        console.log('userDetais fetch !!!!!1', );
        await fetchUserDetails(newId)
      }
    })

    watch(error, (newError) => {
      if (newError) {
        root.$store.dispatch('showAlertError', root.$t('alerts.errors.default'))
      }
    })

    return {
      account,
      loadingAccount,
      loadingAccountId,
      vehicles,
      error,
    }
  },
})
</script>

<style scoped>
.supervisors {
  max-width: 1000px;
}
</style>

<style global>
.data-card {
  background-color: white;
  border-radius: 5px;
  margin: 1.5em;
  padding: 1.5em;
}

.data-card > h2 {
  font-family: 'ProximaNovaSemibold', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 0.6em;
  color: #000;
}
</style>
