<template>
  <div>
    <div class="py-8 todo-list-container px-9">
      <v-card-title class="p-0 pb-7">{{ $t('offer-manager.create.step-dealer-notes.title') }}</v-card-title>
      <m-todo-list
        :buttonText="$t('leads.detail.follow-up.add')"
        :dateText="$t('leads.detail.follow-up.due-date')"
        :errorText="$t('leads.detail.follow-up.error')"
        :placeholder="$t('leads.detail.follow-up.placeholder')"
        :title="$t('leads.detail.follow-up.title')"
        @input="updateComments"
        v-model="commentLog"
        style="ta"
        />
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import apiOffers from '@/api/offers.api'
import { mapDataToComments, mapDealerDataToComments } from '@/mappers/comments'

export default {
  name: 'DealerNotes',
  components: {},
  props: [
    'fromPage',
    'hideContentMargins',
    'hideIconHeader',
    'offerId',
    'panelHideDivider',
    'panelReadonly',
  ],
  data() {
    return {
      moment,
      commentLog: [],
    }
  },
  watch: {
    offerId: function (val) {
      this.commentLog = []
      this.loadComments(val)
    },
  },
  created() {
    this.loadComments(this.offerId)
  },
  methods: {
    async loadComments(offerId) {
      try {
        const { dealerId } = this.group
        const offerResponse = await apiOffers.getOfferById(offerId, dealerId)
        const commentsFromOffer = mapDataToComments(offerResponse.data?.commentLog)

        let commentsFromDealer = []
        const hasDealerComments = commentsFromOffer.some((comment) => !comment.dueDate)

        if (!hasDealerComments) {
          const dealerResponse = await apiOffers.getDealerCommentsByOfferId(offerId, dealerId)
          commentsFromDealer = mapDealerDataToComments(dealerResponse.data)
        }

        this.commentLog = [...commentsFromOffer, ...commentsFromDealer]
      } catch (error) {
        console.error(error)
      }
    },
    async updateComments(comments) {
      try {
        await apiOffers.updateOfferComments(
          this.offerId,
          { commentLog: comments },
          this.group.dealerId,
        )

        this.$gtm.trackEvent({
          category: 'Offers',
          action: 'CreateDealerAnnotationInOffer',
          label: this.fromPage,
        })
      } catch (error) {
        console.error(error)
      }
    },
  },
  computed: {
    group() {
      return this.$store.getters.getGroup
    },
  },
}
</script>
<style lang="scss" scoped>
  $_gray-color: #ededed;

  .v-card__title{
      font-family: 'ProximaNovaExCnSbold', sans-serif;
      font-size: 24px;
      color: var(--v-primary-base);
      text-transform: uppercase;
      padding-left: 0;
  }

  .todo-list-container{
    border: 1px solid $_gray-color;
    border-radius: 8px;
  }

  .todolist{
    display: flex;
    flex-direction: column-reverse;
  }
</style>
