<template>
  <th class="align-left" v-if="isHeader">
    {{ title }}
  </th>
  <span class="f-s-14" v-else><b>{{ getContainerDetailParam(rowData.container, 'Model') }}</b><br /></span>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'

export default {
  mixins: [VuetableFieldMixin],
  props: {
    rowData: {
      type: Object,
      default: () => {
        return {
          container: {
            detail: {
              Model: '-',
            },
          },
        }
      },
    },
  },
  methods: {
    getContainerDetailParam(container, key) {
      const detail = typeof container === 'string' ? JSON.parse(container) : container.detail
      return detail[key]
    },
  },
  name: 'Model',
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>
