<template>
  <th class="b-r-1" v-if="isHeader">
    {{ title }}
  </th>
  <div v-else-if="rowData">
    <span class="f-s-14">
      <router-link class="color-gray-dark" :to="{ name: 'User', params: { userId: rowData.customer.id } }">
        <strong> {{ getCustomerName(rowData.customer) }}</strong>
      </router-link>
    </span>
  </div>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'

export default {
  mixins: [VuetableFieldMixin],
  props: {
    rowData: {
      type: Object,
      default: () => {
        return { customer: { id: null, detail: null } }
      },
    },
  },
  methods: {
    getCustomerName(customer) {
      let fullname = ''
      if (customer.detail) {
        fullname =
          typeof customer.detail === 'string'
            ? JSON.parse(customer.detail).Name + ' ' + JSON.parse(customer.detail).Surname
            : customer.detail.Name + ' ' + customer.detail.Surname
      }
      return fullname
    },
  },
  name: 'Client',
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>
