// @ts-ignore
import moment from 'moment';
import ApiGatewayClient from './aws/aws-gateway-client'

const REASON_TYPES = {
  TYRES: 'TYRES',
  SERVICE: 'SERVICE',
  OTHER: 'OTHER',
  PAINT: 'PAINT',
  FAILURE: 'FAILURE'
};

interface PossibleStates {
  accepted: boolean
  pending: boolean
  cancelled: boolean
}

interface PossibleStatuses {
  accepted: boolean
}

export class RepairOrdersApi {
  private readonly client: ApiGatewayClient

  constructor() {
    this.client = new ApiGatewayClient()
  }

  // ############ MAPPINGS ⬇️ ############
  private mapAppointmentData (input:any) {
    return {
      container: {
        detail: {
          Id: input.vehicle?.id || '',
          Model: input.vehicle?.model || '',
          Plate: input.vehicle?.registrationNumber || '',
          LegacyId: input.vehicle?.legacyId || '',
        },
      },
      customer: {
        detail: {
          Name: input.account?.firstName || '',
          Surname: input.account?.lastName || '',
          Email: input.account?.email || '',
          Phone: input.account?.phone || '',
        },
        id: input.account?.legacyId?.toString() || '',
      },
      inspection: {
        ...input.inspection,
        nextService: input.nextService,
      },
      request: {
        requested: {
          tyres: input.reason?.includes(REASON_TYPES.TYRES) || false,
          service: input.reason?.includes(REASON_TYPES.SERVICE) || false,
          other: input.reason?.includes(REASON_TYPES.OTHER) ? input.otherReason : false,
          paint: input.reason?.includes(REASON_TYPES.PAINT) || false,
          failure: input.reason?.includes(REASON_TYPES.FAILURE) || false
        },
        status: input.state || '',
        courtesyBike: {
          requested: input.courtesyBike?.requested || false,
          status: input.courtesyBike?.status || '',
        },
        appointment: {
          id: input.id.startsWith('apt-') ? input.id : input.appointmentId,
          day: input.petitionDate ? moment(input.petitionDate).format('DD/MM/YYYY') : '',
          hour: input.petitionHour || '',
        },
        comment: input.comment || '',
        pickup: {
          day: input.pickupDate ? moment(input.pickupDate).format('DD/MM/YYYY') : '',
          hour: input.pickupHour || '',
        },
        repairOrder: {
          id: input.id,
          day: input.petitionDate ? moment(input.petitionDate).format('DD/MM/YYYY') : '',
          hour: input.petitionHour || '',
        },
      },
      dealerId: input.dealerId || '',
      createdAt: input.createdAt || '',
      updatedAt: input.updatedAt || '',
      otherReason: input.otherReason || '',
    };
  }

  private parseStates(states: PossibleStates) {
    const parsedStates = []
    if (states.pending) {
      parsedStates.push('PENDING')
    }
    if (states.accepted) {
      parsedStates.push('ACCEPTED')
    }
    if (states.cancelled) {
      parsedStates.push('CANCELLED_USER')
    }

    if (parsedStates.length === 0) {
      parsedStates.push('NONE')
    }
    return parsedStates
  }

  private parseStatuses(statuses: PossibleStatuses) {
    const parsedStatuses = []
    if (statuses.accepted) {
      parsedStatuses.push('ACCEPTED')
    }

    if (parsedStatuses.length === 0) {
      parsedStatuses.push('NONE')
    }
    return parsedStatuses
  }

  // ############ APPOINTMENTS ⬇️ ############
  async listAppointments(dealerId: string, states: PossibleStates): Promise<any> {
    const res = await this.client.sendRequest({
      endpoint: process.env.VUE_APP_REPAIR_ORDER_API,
      method: 'GET',
      path: `/v1/appointments/dealer/${dealerId}`,
      queryParams: {
        state: this.parseStates(states).join(','),
        limit: 250
      }
    })

    if (!res?.data) {
      return Promise.resolve([])
    }
    
    const sortedData = res.data?.sort((a: any, b: any) => {
      const stateOrder: { [key: string]: number } = {
        PENDING: 1,
        ACCEPTED: 2,
        CANCELLED_USER: 3,
      };

      const stateComparison = stateOrder[a.state] - stateOrder[b.state];
      if (stateComparison !== 0) {
        return stateComparison;
      }

      return new Date(b.petitionDate).getTime() - new Date(a.petitionDate).getTime();
    });


    return Promise.resolve(sortedData.map?.(this.mapAppointmentData))
  }

  async getAppointmentById(appointmentId: string): Promise<any> {
    const res = await this.client.sendRequest({
      endpoint: process.env.VUE_APP_REPAIR_ORDER_API,
      method: 'GET',
      path: `/v1/appointments/${appointmentId}`
    })

    return Promise.resolve(this.mapAppointmentData(res.data))
  }

  async acceptAppointment(appointmentId: string, data: any): Promise<any> {
    await this.client.sendRequest({
      endpoint: process.env.VUE_APP_REPAIR_ORDER_API,
      method: 'PATCH',
      path: `/v1/appointments/${appointmentId}/accept`,
      data
    })
  }

  async cancelAppointment(appointmentId: string): Promise<any> {
    await this.client.sendRequest({
      endpoint: process.env.VUE_APP_REPAIR_ORDER_API,
      method: 'DELETE',
      path: `/v1/appointments/${appointmentId}`
    })
  }

  // ############ REPAIR ORDERS ⬇️ ############
  async listRepairOrders(dealerId: string, statuses: PossibleStatuses): Promise<any> {
    const res = await this.client.sendRequest({
      endpoint: process.env.VUE_APP_REPAIR_ORDER_API,
      method: 'GET',
      path: `/v1/repair-orders/dealer/${dealerId}`,
       queryParams: {
        state: this.parseStatuses(statuses).join(',') 
      }
    })

    if (!res?.data) {
      return Promise.resolve([])
    }

    return Promise.resolve(res.data?.map?.(this.mapAppointmentData))
  }

  async getRepairOrderById(repairOrderId: string): Promise<any> {
    const res = await this.client.sendRequest({
      endpoint: process.env.VUE_APP_REPAIR_ORDER_API,
      method: 'GET',
      path: `/v1/repair-orders/${repairOrderId}`
    })

    return Promise.resolve(this.mapAppointmentData(res.data))
  }

  async completeRepairOrder(repairOrderId: string, body: any): Promise<any> {
    await this.client.sendRequest({
      endpoint: process.env.VUE_APP_REPAIR_ORDER_API,
      method: 'PATCH',
      path: `/v1/repair-orders/${repairOrderId}/complete`,
      data: body
    })
  }

  // ############ STATS ⬇️ ############
  async getPendingAppointmentsStats(dealerId: string): Promise<any> {
    const res = await this.client.sendRequest({
      endpoint: process.env.VUE_APP_REPAIR_ORDER_API,
      method: 'GET',
      path: `/v1/appointments/dealer/${dealerId}`,
      queryParams: {
        state: 'PENDING'
      }
    })

    return Promise.resolve(res.data?.length)
  }

  async getAcceptedRepairOrdersStats(dealerId: string): Promise<any> {
    const res = await this.client.sendRequest({
      endpoint: process.env.VUE_APP_REPAIR_ORDER_API,
      method: 'GET',
      path: `/v1/repair-orders/dealer/${dealerId}`,
       queryParams: {
        state: 'ACCEPTED'
      }
    })

    return Promise.resolve(res.data?.length)
  }

  async getAppointmentStatsPerMonth(dealerId: string, year: number, month: number): Promise<any> {
    const res = await this.client.sendRequest({
      endpoint: process.env.VUE_APP_REPAIR_ORDER_API,
      method: 'GET',
      path: `/v1/appointments/dealer/${dealerId}/stats`,
      queryParams: {
        year,
        month
      }
    })

    return Promise.resolve({...res.data, month, year})
  }

  async getPendingAppointmentsCount(dealerId:string): Promise<any> {
    const res = await this.client.sendRequest({
      endpoint: process.env.VUE_APP_REPAIR_ORDER_API, 
      method: 'GET',
      path: `/v1/appointments/dealer/${dealerId}/count`,
    })

    return Promise.resolve(res.data?.pending || 0)
  }

  async getAcceptedRepairOrdersCount(dealerId:string): Promise<any> {
    const res = await this.client.sendRequest({
      endpoint: process.env.VUE_APP_REPAIR_ORDER_API,
      method: 'GET',
      path: `/v1/repair-orders/dealer/${dealerId}/count`,
    })

    return Promise.resolve(res.data?.accepted || 0)
  }
}

export default new RepairOrdersApi()
