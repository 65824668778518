<template>
  <div>
    <v-row class="mx-0">
      <current-revision :currentRevision="request.currentRevision" :inspection="inspection"></current-revision>
    </v-row>

    <v-divider/>

    <v-row class="mx-0">
      <appointment-reason :showWarning="true" :requested="request.requested"></appointment-reason>
    </v-row>

    <v-divider/>

    <v-row class="mx-0">
      <reception-date :interval="request.repairOrder" :containerId=container.legacyId disabled></reception-date>
    </v-row>

    <v-divider/>

    <v-row class="mx-0">
     <pickup-date :interval="request.pickup" :containerId=container.legacyId :not-before="toDate(request.pickup.day)" :init-value="toDate(request.pickup.day)"></pickup-date>
    </v-row>

    <v-divider/>

    <v-row class="mx-0">
      <repair-cost :request='request' :disabled="isCostDisabled"></repair-cost>
    </v-row>

    <v-divider/>

    <v-row class="mx-0">
      <repair-place :location='request.location'></repair-place>
    </v-row>

    <v-divider/>

    <v-row class='col-12 col-lg-12'>
      <customer-comments :request="request"></customer-comments>
    </v-row>


    <v-row no-gutters v-if="!isValid">
      <v-col class="text-center p-t-20 text-error ">
        <div>
          <i class="fa fa-exclamation-circle pr-2"/>{{ $t('honda.repair.error') }}
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="col-12 p-t-20">
        <v-row no-gutters>
          <v-col class='col-5 text-right'>
            <button class='btn col-auto btn-success' :disabled="!isValid" @click="accept"><i class="fas fa-angle-right"></i> Pasar Reparación
            </button>
          </v-col>
          <v-col class='col-1'></v-col>
          <v-col class='col-5 text-left'>
            <button class='btn col-auto btn-error' @click="$emit('reject')"><i class="fas fa-angle-right"></i> Eliminar Reparación
            </button>
          </v-col>
        </v-row>
      </v-col>


      <v-col class='text-center p-t-10'>
        <div class="form-group">
          <label class="form-checkbox">
            <input type="checkbox" v-model="request.notifyUser" /><i class="form-icon"></i>
            {{ $t('honda.repair.notify') }}
          </label>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Vue from 'vue';
  import CustomerInfo from '@/components/dialog/CustomerInfo';
  import AppointmentReason from '@/components/dialog/AppointmentReason';
  import ReceptionDate from '@/components/dialog/ReceptionDate';
  import PickupDate from '@/components/dialog/PickupDate';
  import CustomerComments from '@/components/dialog/CustomerComments';
  import RepairCost from '@/components/dialog/RepairCost';
  import RepairPlace from '@/components/dialog/RepairPlace';
  import CurrentRevision from '@/components/dialog/CurrentRevision';
  import moment from "moment"

  export default {
    props: ['request', 'customer', 'container', 'inspection'],
    components: {
      CustomerInfo,
      AppointmentReason,
      ReceptionDate,
      PickupDate,
      CustomerComments,
      RepairCost,
      RepairPlace,
      CurrentRevision
    },
    methods: {
      accept() {
        this.$validator.validate().then((result) => {
          if(result) this.$emit('accept');
        });
      },
      toDate: (d => moment(d, 'YYYY-MM-DD').toDate())
    },
    computed: {
      isValid() {
        return this.errors.items.length <= 0;
      },
      isCostDisabled() {
        return !this.request.requested.service;
      }
    }
  }
</script>
