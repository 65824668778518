<template>
  <div class="revisions form-card ultra-high-size">
    <h3 class="align-left p-t-0 p-b-20 honda-plus-title" style="border-bottom: 1px solid black">
      GESTIÓN DE CITAS
    </h3>

    <v-row no-gutters>
      <div class="d-flex flex-nowrap flex-sm-wrap justify-space-between full-width">
        <div class="d-flex flex-wrap">
          <button
            class="btn btn-block btn-honda-plus mr-2 text-no-wrap mb-1 btn-radius"
            aria-hidden="true"
            v-bind:class="{ 'btn-disabled': !request_data.pending }"
            @click="request_data.pending = !request_data.pending"
            :disabled="loading"
          >
            <i class="fas fa-exclamation-circle"></i>
            {{ this.$t('appointments.table.filters.requestedAppointments') }}
          </button>
          <button
            class="btn btn-block btn-honda-plus mr-2 text-no-wrap mb-1 btn-radius"
            v-bind:class="{ 'btn-disabled': !request_data.accepted }"
            @click="request_data.accepted = !request_data.accepted"
            :disabled="loading"
          >
            <i class="fas fa-check-circle" aria-hidden="true"></i>
            {{ this.$t('appointments.table.filters.acceptedAppointments') }}
          </button>
          <button
            class="btn btn-block btn-honda-plus mr-2 text-no-wrap mb-1 btn-radius"
            aria-hidden="true"
            v-bind:class="{ 'btn-disabled': !request_data.cancelled }"
            @click="request_data.cancelled = !request_data.cancelled"
            :disabled="loading"
          >
            <i class="fas fa-times-circle"></i>
            {{ this.$t('appointments.table.filters.cancelledAppointments') }}
          </button>
        </div>
        <div class="text-no-wrap align-self-end mb-1 ml-auto flex-grow-1">
          <v-row no-gutters>
            <m-text-field
              class="custom-search flex-grow-1"
              @keydown.enter="load()"
              hide-details
              outlined
              :placeholder="$t('honda.registration.list.placeholder_search')"
              v-model="searchQuery"
            >
            </m-text-field>
            <m-btn class="btn-primary" @click="load()">
              <v-icon class="icon-mapit">?</v-icon>
            </m-btn>
          </v-row>
        </div>
      </div>

      <revision-modal
        v-if="activeModal"
        :request="modalData.request"
        :container="modalData.container"
        :customer="modalData.customer"
        :active="activeModal"
        @closePopup="closeModal"
        :step="step"
      >
      </revision-modal>
    </v-row>
    <data-table-base
      id="appointments"
      :loading="loading || loadingModal"
      :headers="columns"
      :items="important_services"
      :searchTerm="searchQuery"
      :loadingText="this.$t('appointments.table.loading')"
      :compareFn="compareRevisionDates"
      @openModal="openModal"
    />
  </div>
</template>
<script>
import RevisionModal from './RevisionModal.vue'
import DataTableBase from '../general/DataTableBase.vue'
import { eventBus } from '@/main'
import { mapGetters } from 'vuex'
import repairOrdersApi from '@/api/repairOrders.api'
import moment from 'moment'

export default {
  name: 'RevisionsList',
  components: {
    DataTableBase,
    RevisionModal,
  },
  data() {
    var sortOrders = {}
    var columns = [
      {
        text: this.$t('appointments.table.headers.model'),
        value: 'model',
        class: 'text-header',
      },
      {
        text: this.$t('appointments.table.headers.registrationNumber'),
        value: 'plate',
        class: 'text-header',
      },
      {
        text: this.$t('appointments.table.headers.name'),
        value: 'client',
        class: 'text-header',
      },
      {
        text: this.$t('appointments.table.headers.status'),
        value: 'appointmentStatus',
        class: 'text-header',
      },
      {
        text: this.$t('appointments.table.headers.reason'),
        value: 'appointmentReason',
        class: 'text-header',
      },
      {
        text: this.$t('appointments.table.headers.kmNextRevision'),
        value: 'kmnextrevision',
        class: 'text-header text-no-wrap',
        rowClass: 'text-center',
      },
      {
        text: this.$t('appointments.table.headers.date'),
        value: 'appointmentDay',
        class: 'text-header',
        path: 'request.appointment.day',
        sortable: true,
      },
      {
        text: this.$t('appointments.table.headers.time'),
        value: 'appointmentTime',
        class: 'text-header',
      },
      {
        text: this.$t('appointments.table.headers.appointment'),
        value: 'appointmentButton',
        align: 'center',
        class: 'text-header',
        rowClass: 'text-center',
      },
      {
        text: this.$t('appointments.table.headers.courtesy'),
        value: 'appointmentHasContainer',
        class: 'text-header text-no-wrap',
        align: 'center',
        rowClass: 'text-center',
      },
    ]

    let now = new Date()
    let minDate = new Date()
    let maxDate = new Date()
    let firstDate = new Date()
    minDate.setDate(now.getDate() + 7)
    maxDate.setDate(now.getDate() + 120)
    if (now.getDay() === 6) firstDate.setDate(now.getDate() + 9)
    else firstDate.setDate(now.getDate() + 8)

    return {
      activeModal: false,
      modalData: {},
      sortKey: 'prevision',
      sortOrders: sortOrders,
      all_services: [],
      important_services: [],
      searchQuery: '',
      columns,
      editMode: null,
      defaultDate: firstDate,
      testEdit: {
        dateSelected: firstDate,
        schedule: 'Mañana',
        comment: '',
      },
      disabledDates: {
        to: minDate, // Disable all dates up to specific date
        from: maxDate, // Disable all dates after specific date
        days: [0], // Disable Saturday's and Sunday's
      },
      request_data: {
        accepted: true,
        cancelled: true,
        pending: true,
      },
      page: 1,
      max: 20,
      bottom: false,
      noMoreResults: false,
      loading: false,
      loadingModal: false,
      ORDERBY: 'PETITION_DESC',
      step: 0,
    }
  },
  props: ['id'],
  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
    isFiltered() {
      return Object.values(this.request_data).filter(Boolean).length > 0
    },
    token() {
      return this.$store.getters.getToken
    },
    user() {
      return this.$store.getters.getUser
    },
  },
  watch: {
    searchQuery: {
      handler() {
        this.filterAppointments()
      },
    },
    bottom(bottom) {
      if (bottom) {
        this.loadMoreRevisions()
      }
    },
    request_data: {
      deep: true,
      handler() {
        this.load()
      },
    },
  },
  methods: {
    async openModal({ payload }) {
      const id = payload?.request?.id || payload?.request?.appointment?.id

      if (!id) {
        console.error('No id found in payload:', payload)
        return
      }

      this.$router.push('/appointments/' + id)

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      self.loadingModal = true

      await repairOrdersApi
        .getAppointmentById(id)
        .then((apt) => {
          if (apt) {
            self.loadingModal = false
            self.modalData = {
              request: {
                appointment: {
                  ...apt.request.appointment,
                  day: moment(apt.request.appointment.day, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                },
                requested: apt.request.requested,
                courtesyBike: apt.request.courtesyBike,
                comment: apt.request.comment,
              },
              container: {
                id: apt.container.detail.Id,
                legacyId: apt.container.detail.LegacyId,
                detail: JSON.stringify(apt.container.detail),
              },
              customer: {
                detail: JSON.stringify(apt.customer.detail),
              },
            }

            self.activeModal = true
            self.step = 0
            eventBus.$emit('refresh-counters')
          }
        })
        .catch((err) => {
          console.error('Error getting appointment:', err)
          self.loadingModal = false
          self.activeModal = false
          self.step = 5
        })
    },
    closeModal() {
      this.activeModal = false
      if ('id' in this.$route.params) {
        this.load(true)
        this.$router.push('/appointments')
      }
    },
    filterAppointments() {
      const searchLower = this.searchQuery.toLowerCase()

      this.important_services = this.important_services.filter((apt) => {
        const searchableFilters = [
          apt.container.detail.Model,
          apt.container.detail.Plate,
          apt.customer.detail.Name,
          apt.customer.detail.Surname,
        ]

        return searchableFilters.some((field) =>
          field?.toString().toLowerCase().includes(searchLower),
        )
      })
    },
    async load() {
      this.page = 1
      this.max = 50
      this.noMoreResults = false
      this.loading = true

      //todas
      try {
        const appointments = await repairOrdersApi.listAppointments(
          this.user.dealerId,
          this.request_data,
        )

        this.important_services = appointments

        this.filterAppointments()

        this.loading = false
        this.page++
      } catch (error) {
        console.error(`Error loading appointments: ${error}`)
        this.loading = false
      }
    },
    reload() {
      this.load(true)
    },
    //COMPROVAR SI ESTEM AL FINAL DE LA PAGINA
    bottomVisible() {
      const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight

      return bottomOfPage || pageHeight < visible
    },
    loadMoreRevisions() {
      if (!this.noMoreResults) {
        this.loading = true

        let body = {}
        if (this.searchQuery && this.searchQuery.length > 0) {
          body = {
            groupId: this.user.groupId,
            priority: false,
            request_data: this.request_data,
            search: this.searchQuery,
            status: 0,
            page: this.page,
            max: this.max,
            order: this.ORDERBY,
          }
        } else {
          body = {
            groupId: this.user.groupId,
            priority: false,
            request_data: this.request_data,
            status: 0,
            page: this.page,
            max: this.max,
            order: this.ORDERBY,
          }
        }

        this.loading = false
      }
    },
    compareRevisionDates(valA, valB) {
      const dateA = moment(valA, 'DD/MM/YYYY')
      const dateB = moment(valB, 'DD/MM/YYYY')
      return dateA.diff(dateB)
    },
  },
  created() {
    window.addEventListener('scroll', () => {
      if (!this.loading) {
        this.bottom = this.bottomVisible()
      }
    })
    eventBus.$on('refresh-list', () => {
      this.load(true)
    })
    if ('id' in this.$route.params) {
      this.openModal({ payload: { request: { id: this.$route.params.id } } })
    } else {
      this.load(true)
    }
  },
  destroyed() {
    eventBus.$off('refresh-list')
  },
}
</script>
<style></style>
