<template>
  <div>
    <customer-info :customer="customer" :container="container" :modified="modified"></customer-info>

    <v-row class="mx-0">
      <appointment-reason :requested="request.requested"></appointment-reason>
    </v-row>

    <v-divider/>

    <v-row class="mx-0">
      <reception-date :interval="request.appointment" :containerId=container.legacyId></reception-date>
    </v-row>

    <v-divider/>

    <v-row class="mx-0">
      <pickup-date :interval="request.pickup" :containerId=container.legacyId :notBefore="toDate(request.appointment.day)"
                   :init-value="toDate(request.appointment.day)"
      ></pickup-date>
    </v-row>

    <v-divider/>

    <v-row class="mx-0">
      <container-courtesy :courtesy="request.courtesyBike" :editable="editableCourtesy"></container-courtesy>
    </v-row>

    <v-divider/>

    <v-row class="mx-0">
      <customer-comments :request="request" :editable="editableComments"></customer-comments>
    </v-row>

    <v-row class="mx-0 justify-center" v-if="modified">
      <div class="modified-alert">
        <i class="fa fa-exclamation-circle pr-2"/>Se han modificado datos de la cita, antes de 'Aceptar cita' póngase en
        contacto con el cliente para confirmar la cita
      </div>
    </v-row>


  </div>
</template>

<script>
  import Vue from 'vue';
  import CustomerInfo from '@/components/dialog/CustomerInfo';
  import AppointmentReason from '@/components/dialog/AppointmentReason';
  import ReceptionDate from '@/components/dialog/ReceptionDate';
  import PickupDate from '@/components/dialog/PickupDate';
  import CustomerComments from '@/components/dialog/CustomerComments';
  import ContainerCourtesy from '@/components/dialog/ContainerCourtesy';
  import moment from 'moment'

  export default {
    name: 'RevisionDetail',
    props: {
      request: Object,
      customer: Object,
      container: Object,
      showModificationAlert: {
        type: Boolean,
        default: true
      },
      editableCourtesy: {
        type: Boolean,
        default: false
      },

      editableComments: {
        type: Boolean,
        default: false
      },
    },
    components: {
      CustomerInfo,
      AppointmentReason,
      ReceptionDate,
      PickupDate,
      CustomerComments,
      ContainerCourtesy
    },
    data() {
      return {
        modified: false
      };
    },
    methods: {
      toDate: (d => moment(d, 'YYYY-MM-DD').toDate())
    },
    watch: {
      "request.appointment": {
        deep: true,
        handler() {
          if (this.showModificationAlert) {
            this.modified = true
          }
        }
      }
    }
  }
</script>

<style>
  .modified-alert {
    background-color: yellow !important;
    color: red !important;
    margin-top: 20px;
    text-align: center;
  }
</style>
