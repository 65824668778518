import ApiGatewayClient from "./aws/aws-gateway-client"

export class PredeliveriesApi {
  private readonly client: ApiGatewayClient

  constructor() {
    this.client = new ApiGatewayClient()
  }

  getPredeliveriesByVin(vin: string, dealerId: string) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_OFFERS_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}/predeliveries/${vin}`,
    })
  }
}

export default new PredeliveriesApi()
