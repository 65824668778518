<template>
  <div class="pdf-viewer py-8">
    <v-card-title class="px-9 py-0">{{ $t('offer-manager.create.step-resume.title') }}</v-card-title>
    <v-card-text class="px-9 pt-1 pb-0" v-if="code">{{ `${$t('offer-manager.create.step-resume.offer-number')} ${code}` }}</v-card-text>
    <div class="d-flex justify-center flex-column pdf-viewer__wrapper">
      <pdf-viewer-card :uid="uid" :pdfPath="pdfPath" :showPdfViewer="showPdfViewer" :code="code" @onOpenDialog="handleOpenCloseDialog()" />
    </div>
    <mapit-dialog :dialog="isDialogOpen" @close="handleOpenCloseDialog()" customWidth="1050px">
      <template v-slot:body>
        <pdf-viewer-card :uid="uid" :pdfPath="pdfPath" :showPdfViewer="showPdfViewer" :code="code" />
      </template>
    </mapit-dialog>
  </div>
</template>

<script>
import MapitDialog from '@/components/dialog/MapitDialog.vue'
import PdfViewerCard from '@/components/general/PdfViewerCard.vue'

export default {
  name: "PdfViewer",
  props: ['uid', 'pdfPath', 'showPdfViewer', 'code'],
  data() {
    return {
      isDialogOpen: false
    }
  },
  components: {
    MapitDialog,
    PdfViewerCard
  },
  created() {
    this.$root.$on('printPdf', this.printPdf)
  },
  methods: {
    handleOpenCloseDialog(){
      this.isDialogOpen = !this.isDialogOpen
    }
  },
};
</script>
<style lang="scss" scoped>
  .v-card__title{
    font-family: 'ProximaNovaExCnSbold', sans-serif;
    font-size: 24px;
    color: var(--v-primary-base);
    text-transform: uppercase;
  }

  .v-card__text{
    font-size: 16px;
  }

  .pdf-viewer{
    border: 1px solid #EDEDED;
    border-radius: 8px;

    &__wrapper{
      padding-left: 10px;

      @media screen and (max-width: 1500px) {
        padding-left: 0;
        align-items: center;
      }
    }
  }
</style>
