<template>
  <v-navigation-drawer
    :class="drawer ? 'mini-drawer' : 'regular-drawer'"
    :mini-variant-width="miniDrawerWidth"
    :mini-variant="drawer"
    :width="DEFAULT_WIDE_WIDTH"
    fixed
    id="main-nav-bar"
    left
    permanent
    touchless
  >
    <v-list nav dense expand ref="list" id="navbar-list" :class="isOverflown ? 'overflown' : ''">
      <v-list-item-group>
        <router-link :to="{ name: 'Dashboard' }">
          <v-list-item :class="route !== 'Dashboard' || 'nav-selected'">
            <v-list-item-title class="nav-link">
              <v-icon dense color="primary">fa-light fa-house</v-icon>
              <span class="listItemLink">{{ $t('menu.dashboard') }}</span>
            </v-list-item-title>
          </v-list-item>
        </router-link>

        <div v-if="permissions.leads.canSee">
          <v-divider class="drawer-divider"></v-divider>
          <router-link :to="{ name: 'Leads' }">
            <v-list-item :class="(route !== 'Leads' && route !== 'LeadDetail') || 'nav-selected'">
              <v-list-item-title class="nav-link">
                <v-icon dense color="primary">fa-light fa-bullseye-arrow</v-icon>
                <span class="listItemLink">{{ $t('menu.leads') }}</span>
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </div>
        <v-divider class="drawer-divider"></v-divider>

        <div v-if="permissions.offers.canSee">
          <v-list-group :value="true" color="gray" @click="sendGAEvent('collapsed', 'Ofertas')">
            <template v-slot:activator>
              <v-list-item-title class="nav-link">
                <v-icon dense color="primary" size="16" style="width: 34px"
                  >fa-light fa-file-lines</v-icon
                >
                <span class="listItemLink">{{ $t('menu.offers') }}</span>
              </v-list-item-title>
            </template>

            <router-link :to="{ name: 'InitOffer' }">
              <v-list-item
                :class="
                  (route !== 'InitOffer' &&
                    route !== 'CreateOfferMotorbike' &&
                    route !== 'ResumeOfferMotorbike') ||
                  'nav-selected'
                "
              >
                <v-list-item-title class="nav-link-sublist">
                  <v-icon dense color="primary">fak fa-light fa-m-file-plus</v-icon>
                  <span class="listItemLink">{{ $t('menu.create-offer') }}</span>
                </v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link :to="{ name: 'Offers' }">
              <v-list-item
                :class="(route !== 'Offers' && route !== 'EditOfferMotorbike') || 'nav-selected'"
              >
                <v-list-item-title class="nav-link-sublist">
                  <v-icon dense color="primary">fak fa-light fa-m-file-list</v-icon>
                  <span class="listItemLink">{{ $t('menu.list-offers') }}</span>
                </v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-group>
          <v-divider class="drawer-divider"></v-divider>
        </div>

        <div v-if="permissions.dealerBikes.canSee">
          <v-list-group :value="true" color="gray" @click="sendGAEvent('collapsed', 'dealer-bike')">
            <template v-slot:activator>
              <v-list-item-title class="nav-link">
                <v-icon dense color="primary" size="15"
                  >fak fa-light fa-m-motorcycle-touring</v-icon
                >
                <span class="listItemLink">{{ $t('menu.dealer-bike') }}</span>
              </v-list-item-title>
            </template>

            <router-link :to="{ name: 'DealerBikesList' }">
              <v-list-item
                :class="route !== 'DealerBikesList' || 'nav-selected'"
                data-cy="main-nav-bar-dealer-bike-list"
              >
                <v-list-item-title class="nav-link-sublist">
                  <v-icon dense color="primary">fak fa-light fa-m-motorcycles</v-icon>
                  <span class="listItemLink">{{ $t('menu.dealer-bike-list') }}</span>
                </v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link :to="{ name: 'LoansList' }">
              <v-list-item
                :class="route !== 'LoansList' || 'nav-selected'"
                data-cy="main-nav-bar-dealer-bike-loans"
              >
                <v-list-item-title class="nav-link-sublist">
                  <v-icon dense color="primary">fak fa-light fa-m-motorcycles-right-left</v-icon>
                  <span class="listItemLink">{{ $t('menu.dealer-bike-loans') }}</span>
                </v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-group>
          <v-divider class="drawer-divider"></v-divider>
        </div>

        <div v-if="permissions.registrations.canSee || permissions.customers.canSee">
          <v-list-group
            id="honda-plus-registration"
            :value="true"
            color="gray"
            @click="sendGAEvent('collapsed', 'Altas')"
          >
            <template v-slot:activator>
              <v-list-item-title class="nav-link">
                <v-icon dense color="primary" size="15">fak fa-light fa-m-motorcycle-street</v-icon>
                <span class="listItemLink">{{ $t('menu.registrations') }}</span>
              </v-list-item-title>
            </template>
            <router-link
              v-if="permissions.registrations.canSee && isHondaPlusFullSupported"
              :to="{ name: 'HondaRegistration' }"
            >
              <v-list-item
                :ripple="true"
                :selectable="true"
                :class="route === 'HondaRegistration' && !hasForcedType ? 'nav-selected' : ''"
              >
                <v-list-item-title class="nav-link-sublist" v-if="isHondaPlusFullSupported">
                  <v-icon dense color="primary">fak fa-light fa-m-motorcycle-h</v-icon>
                  <span class="listItemLink">{{ $t('menu.new-alta-honda') }}</span>
                </v-list-item-title>
              </v-list-item>
            </router-link>

            <tooltip
              v-else-if="isHondaPlusPromoSupported"
              :attach="drawer ? 'registration-target' : undefined"
              :position="drawer ? 'right' : 'top'"
              :text="$t('error.not-available')"
              :width="220"
            >
              <v-list-item :ripple="false" :selectable="false" style="cursor: default">
                <v-list-item-title class="nav-link-sublist" disabled style="opacity: 0.35">
                  <v-icon dense color="primary">fak fa-light fa-m-motorcycle-h</v-icon>
                  <span class="listItemLink">{{ $t('menu.new-alta-honda') }}</span>
                </v-list-item-title>
              </v-list-item>
            </tooltip>

            <router-link
              :to="{ name: 'RegistrationOnlyMapit' }"
              v-if="permissions.registrations.canSee"
            >
              <v-list-item
                :class="
                  route === 'RegistrationOnlyMapit' ||
                  route === 'TransferredVehicles' ||
                  route === 'RegistrationMapitConnect' ||
                  hasForcedType
                    ? 'nav-selected'
                    : ''
                "
              >
                <v-list-item-title class="nav-link-sublist">
                  <v-icon dense color="primary">fak fa-light fa-m-motorcycle-m</v-icon>
                  <span class="listItemLink">{{ $t('menu.new-alta-connect') }}</span>
                </v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link :to="{ name: 'Registrations' }">
              <v-badge
                v-if="permissions.registrations.canSee"
                :class="drawer ? 'custom-badge-mini' : 'custom-badge-subgroup'"
                :content="registrations"
                :value="registrations > 0"
                class="badge-styles"
                color="yellow"
                left
              >
                <v-list-item :class="route !== 'Registrations' || 'nav-selected'">
                  <v-list-item-title class="nav-link-sublist">
                    <v-icon dense color="primary">fak fa-light fa-m-motorcycle-clock</v-icon>
                    <span class="listItemLink">{{ $t('menu.pending-registrations') }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-badge>
            </router-link>

            <router-link v-if="permissions.customers.canSee" :to="{ name: 'Users' }">
              <v-list-item
                :class="
                  (route !== 'Users' &&
                    route !== 'Container' &&
                    route !== 'User' &&
                    route !== 'UsersId') ||
                  'nav-selected'
                "
              >
                <v-list-item-title class="nav-link-sublist">
                  <v-icon dense color="primary">fa-light fa-user-group</v-icon>
                  <span class="listItemLink">{{ $t('menu.users') }}</span>
                </v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-group>
          <v-divider class="drawer-divider"></v-divider>
        </div>

        <div v-if="permissions.appointments.canSee">
          <v-list-group
            v-if="isMaintenanceFullSupported"
            :value="true"
            color="gray"
            @click="sendGAEvent('collapsed', 'Citas')"
          >
            <template v-slot:activator>
              <v-list-item-title class="nav-link">
                <v-icon dense color="primary" size="14">fak fa-light fa-m-calendar</v-icon>
                <span class="listItemLink">{{ $t('menu.citas') }}</span>
              </v-list-item-title>
            </template>

            <router-link :to="{ name: 'Revisions' }">
              <v-badge
                left
                :class="drawer ? 'custom-badge-mini' : 'custom-badge-subgroup'"
                color="yellow"
                :content="revisionsNotify"
                :value="revisionsNotify > 0"
              >
                <v-list-item
                  :class="(route !== 'Revisions' && route !== 'RevisionsById') || 'nav-selected'"
                >
                  <v-list-item-title class="nav-link-sublist">
                    <v-icon dense color="primary" size="14"
                      >fak fa-light fa-m-calendar-clock</v-icon
                    >
                    <span class="listItemLink">{{ $t('menu.revisions') }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-badge>
            </router-link>

            <router-link :to="{ name: 'Repairs' }">
              <v-badge
                left
                :class="drawer ? 'custom-badge-mini' : 'custom-badge-subgroup'"
                color="yellow"
                :content="repairsNotify"
                :value="repairsNotify > 0"
              >
                <v-list-item
                  :class="(route !== 'Repairs' && route !== 'RepairsById') || 'nav-selected'"
                  style="margin-bottom: 0"
                >
                  <v-list-item-title class="nav-link-sublist">
                    <v-icon dense color="primary">fa-light fa-wrench</v-icon>
                    <span class="listItemLink">{{ $t('menu.repairs') }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-badge>
            </router-link>
          </v-list-group>
          <tooltip
            v-else-if="isMaintenancePromoSupported"
            :attach="drawer ? 'maintenance-target' : undefined"
            :position="drawer ? 'right' : 'top'"
            :text="$t('error.not-available')"
            :width="220"
          >
            <v-list-item-title class="nav-link" disabled style="opacity: 0.35; width: min-content">
              <v-icon dense color="primary" size="14">fak fa-light fa-m-calendar</v-icon>
              <span class="listItemLink">{{ $t('menu.citas') }}</span>
            </v-list-item-title>
          </tooltip>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { eventBus } from '@/main'
import { detectDevice } from '@/utils/utils'
import { RegistrationTypes } from '@/utils/constants'
import Tooltip from '@/components/atom/Tooltip.vue'
import repairOrdersApi from '@/api/repairOrders.api'

export default {
  name: 'AppNavigationMenu',
  components: { Tooltip },
  data() {
    return {
      revisionsNotify: 0,
      repairsNotify: 0,
      route: this.$route.name,
      isOverflown: false,
      DEFAULT_WIDE_WIDTH: 236,
      DEFAULT_WIDTH: 52,
      SCROLLBAR_WIDTH: 7,
      FIREFOX_SCROLLBAR_WIDTH: 17,
    }
  },
  watch: {
    group: function (val) {
      this.$store.dispatch('setPendingRegistrations', val.dealerId)
    },
    $route(to) {
      this.route = to.name
    },
    drawer() {
      this.setDrawerWidth()
    },
  },
  computed: {
    ...mapState(['permissions', 'user']),
    ...mapGetters({
      branch: 'getBranch',
      group: 'getGroup',
      capabilities: 'getCapabilities',
    }),
    drawer: {
      get() {
        this.checkOverflow()
        return this.$store.getters.getDrawerState
      },
      set(val) {
        this.$store.dispatch('setDrawerState', val)
        this.checkOverflow()
      },
    },
    miniDrawerWidth() {
      const { browser } = detectDevice()
      const drawerWidth = this.DEFAULT_WIDTH
      const scrollbarWidth = browser === 'firefox' ? 0 : this.SCROLLBAR_WIDTH

      return this.isOverflown ? drawerWidth + scrollbarWidth : drawerWidth
    },
    drawerWidth() {
      return `${this.drawer ? this.miniDrawerWidth : this.DEFAULT_WIDE_WIDTH}px`
    },
    registrations() {
      return this.$store.getters.getPendingRegistrations
    },
    queryParams() {
      return this.$route.query
    },
    hasForcedType() {
      return [RegistrationTypes.HONDA_MAPIT, RegistrationTypes.HONDA_MAPIT_TRANSFERRED].includes(
        this.queryParams?.forcedType,
      )
    },
    isHondaPlusFullSupported() {
      return (
        this.capabilities?.hondaPlus?.mode === 'FULL' ?? this.capabilities?.hondaPlus?.supported
      )
    },
    isHondaPlusPromoSupported() {
      return this.capabilities?.hondaPlus?.mode === 'PROMO'
    },
    isMaintenanceFullSupported() {
      return (
        this.capabilities?.maintenance?.mode === 'FULL' ?? this.capabilities?.maintenance?.supported
      )
    },
    isMaintenancePromoSupported() {
      return this.capabilities?.maintenance?.mode === 'PROMO'
    },
  },
  methods: {
    hideDrawer() {
      if (this.drawer) {
        this.$store.dispatch('setDrawerState', false)
      }
    },
    async loadNotify() {
      this.revisionsNotify = await repairOrdersApi.getPendingAppointmentsCount(this.group.dealerId)
      this.repairsNotify = await repairOrdersApi.getAcceptedRepairOrdersCount(this.group.dealerId)
    },
    loadRegistrations() {
      if (this.group && this.group.dealerId) {
        this.$store.dispatch('setPendingRegistrations', this.group.dealerId)
      }
    },
    sendGAEvent(action, label) {
      this.$gtm.trackEvent({ event: 'menu_navigation', category: 'Navigation', action, label })
    },
    checkOverflow() {
      const list = document.querySelector('.v-navigation-drawer__content')
      if (!list) {
        this.isOverflown = false
        return
      }

      this.isOverflown = list.scrollHeight > list.clientHeight
    },
    setDrawerWidth() {
      document.documentElement.style.setProperty('--drawer-width', this.drawerWidth)
    },
  },
  created() {
    if (this.user) {
      this.loadRegistrations()
      this.loadNotify()
    }
    eventBus.$on('refresh-counters', () => {
      this.loadNotify()
    })
  },
  mounted() {
    this.$nextTick().then(() => {
      this.setDrawerWidth()
      this.checkOverflow()
      window.addEventListener('resize', () => {
        this.checkOverflow()
        this.setDrawerWidth()
      })
    })
  },
  destroyed() {
    eventBus.$off('refresh-counters')
    window.removeEventListener('resize', () => {
      this.checkOverflow()
      this.setDrawerWidth()
    })
  },
}
</script>

<style lang="scss">
#main-nav-bar {
  top: 70px !important;
  z-index: 2 !important;

  .v-navigation-drawer__content {
    max-height: calc(100% - 70px) !important;
  }

  .v-list-item__title {
    display: flex;
    align-items: center;
  }

  .v-list-group__header {
    margin-bottom: 0 !important;
  }

  .v-list-item {
    padding: 0 !important;
    height: 36px !important;
    min-height: 36px !important;
  }

  .v-list-item--active::before {
    background: white !important;
  }

  .v-list-item[aria-disabled='true'] {
    opacity: 0.35;
  }

  .v-list-group__items {
    .v-list-item {
      margin-top: 2px !important;
      margin-bottom: 0 !important;
    }
  }

  .fa-chevron-down {
    margin-right: 9px;
    font-size: 16px !important;
    font-family: 'mapitfont-v1';
    color: #bbbbbb !important;

    &:before {
      content: '\32';
    }
  }

  i {
    font-size: 16px;
  }
}

.nav-link-sublist {
  margin-left: 20px;

  .v-icon {
    font-size: 15px;
    padding: 9px !important;
  }
}

.nav-link {
  display: flex;
  flex-direction: row;
  align-items: center;

  .v-icon {
    font-size: 15px;
    padding: 9px !important;
  }
}

.nav-link,
.nav-link-sublist {
  text-decoration: none !important;
  outline: transparent !important;
  border: 0 !important;
  box-shadow: transparent !important;

  div {
    text-decoration: none !important;
    outline: transparent !important;
    border: 0 !important;
    box-shadow: transparent !important;
  }

  .icon-primary {
    color: var(--v-primary-base) !important;
  }
}

.listItemLink {
  font-family: 'ProximaNovaRegular', sans-serif;
  font-size: 14px;
  color: #222222;
  text-decoration: none !important;
}

.custom-badge-subgroup {
  cursor: pointer;
  width: 100%;

  .v-badge__badge {
    color: black !important;
    font-family: 'ProximaNovaRegular', sans-serif;
    font-size: 13px;
    position: relative;
    left: 20px !important;
    top: 0px !important;
  }
}

.custom-badge {
  width: 100%;

  .v-badge__badge {
    color: black !important;
    font-family: 'ProximaNovaRegular', sans-serif;
    font-size: 13px;
    position: relative;
    left: 0px !important;
    top: 0px !important;
  }
}

.custom-badge-mini {
  cursor: pointer;

  .v-badge__badge {
    color: black !important;
    font-family: 'ProximaNovaRegular', sans-serif;
    font-size: 13px;
    position: relative;
    left: 0px !important;
    top: 0px !important;
    user-select: none;
  }
}

.drawer-divider {
  margin-top: 4px;
  margin-bottom: 4px;
}

.v-application--is-ltr
  .v-list--dense.v-list--nav
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 25px !important;
}

.mini-drawer {
  .listItemLink {
    display: none;
  }

  .overflown {
    &#navbar-list {
      overflow-y: auto;
    }
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.nav-selected {
  background: rgba(255, 0, 0, 0.06);
}

.v-list--nav .v-list-item {
  padding: 0 6px !important;
}
</style>
