import { Branch } from '@/utils/constants'
import {
  EchartsFormatType,
  EchartsGraphType,
  EchartsOption,
  GraphData,
} from '@/composables/echarts'

import {
  FinanceConfig,
  FinanceOpts,
  FinanceResult,
} from '@mapit/honda-offers-builder/dist/finance-calculator'

import { Address as AddressDynamoDB, Contact } from '@mapit/contacts-dynamodb/dist/types'
import { Campaign, Material, Model } from '@mapit/honda-catalog-dynamodb/dist/types'
import { Channel, LeadStatus, Opportunity, Prospect } from '@mapit/leads-dynamodb/dist/types'
import { Predelivery } from '@mapit/honda-offers-dynamodb/dist/types'

import {
  Client,
  LogItem,
  Offer,
  OfferType,
  Pack,
  Packs,
  Product,
  Status,
} from '@mapit/honda-offers-builder/dist/types'

export { Client, Offer, Pack, Packs, Product, OfferType, Status, LogItem }
export { OfferDoc, RegistrationDoc } from '@mapit/opensearch-client/dist/types'
export {
  LeadStatus,
  Opportunity,
  OpportunityType,
  Prospect,
} from '@mapit/leads-dynamodb/dist/types'

/* ########################
 * ##### COMMON TYPES #####
 * ########################  */

export interface Audit {
  createdAt?: string
  createdBy?: string
  updatedAt?: string
  updatedBy?: string
  silentUpdatedAt?: string
}

export interface Ref {
  id?: string
}

/* #########################
 * ### LEAD TYPES ###
 * #########################  */

export enum DocumentType {
  NIF = 'NIF',
  Other = 'OTHER',
}

export interface Document {
  type: DocumentType
  number: string
}

export enum ContactType {
  Individual = 'INDIVIDUAL',
  Company = 'COMPANY',
}

export interface Address {
  streetAddress?: string
  addressNumber?: string
  postalCode?: string
  addressLocality?: string
  addressRegion?: string
  country?: string
}

export interface ContactAddress extends AddressDynamoDB {
  country?: string
}

export interface ContactWithExtras extends Contact {
  privacyTermsAccepted: boolean
  address: ContactAddress
}

export interface Preferences {
  locale: string
}
export interface Dealer extends Ref {
  code: string
}

export interface LeadRequest {
  prospect: Prospect
  opportunity: Opportunity
  channel?: Channel
  dealer: Dealer
}

export interface Lead extends LeadRequest, Ref, Audit {
  expiresAt?: string
  status: LeadStatus
  assignee?: string
  dealer: Dealer
  flagged?: boolean
  read?: boolean
  readBy?: string
  readAt?: string
  offerId?: string
  commentLog?: LogItem[]
  statusLog?: LogItem[]
}

export interface DealerData {
  id: string
}

export interface OfferData {
  id: string
}

export interface CatalogModel {
  modelName: string
  name: string
  hondaPlusGo: boolean
}

export interface MapModel extends Model {
  name: string
  subname: string
  idMaterial: string
}

export interface OfferExportParams {
  branch: Branch
  from: string
  to: string
}

export type StatType = 'label' | 'gauge' | '2-rows' | 'pie'
export type StatsDecoration = 'arrow-right' | 'arrow-left' | 'separator-right' | 'separator-left'

export type StatStyle = 'fontSize'
export interface StatData {
  name: string
  value: string
  color?: string
  style?: Partial<{ [key in StatStyle]: string }>
}

export interface TitleData {
  title: string
}

export interface Stats {
  _id?: string
  data: (StatData | TitleData)[]
  type: StatType
  min?: number
  max?: number
  formatType?: EchartsFormatType
  span?: number
  decoration?: StatsDecoration
  color?: string
  height?: string
  echartsOptions?: Omit<EchartsOption, 'series'>
  seriesOptions?: EchartsOption['series']
}

export type StatsFetchType =
  | 'offers'
  | 'leads'
  | 'loans'
  | 'sales'
  | 'appointments'
  | 'demo-bikes'
  | 'honda-plus'

export interface TypeResultStylesData {
  icon: string
  class: string
}

export enum ResultStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  DELETE = 'DELETE',
}

export interface PackItem extends Pack {
  removed?: boolean
}

export interface CustomPacks extends Packs {
  packs: PackItem[]
}

export interface InsuranceTypeListItem {
  text: string
  value: any
}

/* ##################################
 * ### FINANCIAL CALCULATOR TYPES ###
 * ##################################  */

export interface FinancialCalculatorModel {
  mode?: FinanceConfig
  input?: FinanceOpts
  result?: FinanceResult
}

export type BaseData = {
  selectedMode: FinanceConfig
  input: FinanceOpts
  minDepositAmount: number
  formatNumberToCurrency: any
}

/* ###########################
 * ### OFFER MANAGER TYPES ###
 * ###########################  */

export interface OfferExtended extends Offer {
  predelivery?: Predelivery
}

export interface ClientWithExtras extends Client {
  subagent: boolean
}

export interface OfferDates {
  createDate: string
  createdAt: string
}

/* ######################
 * ### PRESALES TYPES ###
 * ######################  */

export type PresaleErrorType = 'WrongImportInput' | 'DuplicatedOfferCode'

export interface PredeliveryResponse {
  success: {
    updated: number
    created: number
    total: number
  }
}

export interface PredeliveryErrorResponse {
  row?: number
  type: PresaleErrorType
}

export interface WrongImportInputErrorResponse extends PredeliveryErrorResponse {
  column?: string
}

export interface DuplicatedOfferCodeErrorResponse extends PredeliveryErrorResponse {
  offerCode?: string
  dealerCode?: string
}

// Response from OpenSearch API
export interface OpenSearchResponse<T> {
  hits?: T[]
  data?: T[]
  total: {
    value: number
    relation: string
  }
}

export interface DealerInfo {
  id: string
  code?: string
}

export interface OpenSearchPagination {
  from: number
  size: number
  column?: string
  sort?: 'asc' | 'desc'
}

export interface OpenSearchListRequest {
  dealer: DealerInfo
  pagination?: Partial<OpenSearchPagination>
  text?: string
  range?: number
  params?: Record<string, any>
  contactId?: string
}

// Dashboard BETA

export type TargetStatusType = 'needs_push' | 'on_track'

export interface DashboardStatResponse {
  dealer_id: string
  year: number
  month: number
}

export interface StatsLeadsResponse extends DashboardStatResponse {
  leads: number
  leads_yoy: number
  attended_48h: number
  attended_48h_perc: number
  attended_48h_target: number
  attended_48h_status: TargetStatusType
  with_offer: number
  with_offer_perc: number
  with_offer_target: number
  with_offer_status: TargetStatusType
}

export interface StatsDemoBikesResponse extends DashboardStatResponse {
  demobikes: number
  demobikes_perc: number
  demobikes_target: TargetStatusType
  loans: number
  loans_yoy: number
  loaned_demo_bikes: number
  loans_vs_sales_perc: number
  loans_vs_sales_target: TargetStatusType
}

export interface StatsOffersResponse extends DashboardStatResponse {
  offers: number
  offers_yoy: number
  coi_created: number
  coi_created_perc: number
  completed: number
  completed_perc: number
  to_be_delivered: number
  current_invoiced: number
  current_confirmed: number
  current_quote: number
  current_coi_created: number
  current_accepted: number
}

export interface StatsRegistrationsResponse extends DashboardStatResponse {
  hp: number
  hp_yoy: number
  hm_perc: number
  ins_perc: number
  fin_perc: number
  hpgo: number
  hpgo_perc: number
}

export interface StatsSalesResponse extends DashboardStatResponse {
  sales: number
  sales_target_100: number
  sales_target_105: number
  weight: number
  weight_target: number
  sales_status: TargetStatusType
  hp: number
  hp_perc: number
  hp_obj: number
  hp_target: number
  hp_status: TargetStatusType
}

export interface StatsAppointmentsResponse extends DashboardStatResponse {
  requests: number
  pending: number
  accepted: number
  finalized_with_appointment: number
  kpi_finalized_accepted: number
}

// EOF · Dashboard BETA

export interface DashboardOfferStatResponse extends DashboardStatResponse {
  total: number
  total_diff_prev_year: number
  total_prev_year: number
  coi_created: number
  coi_created_diff_prev_year: number
  coi_created_prev_year: number
  perc_coi_created: number
  perc_coi_created_prev_year: number
}

export interface DashboardLeadsStatResponse extends DashboardStatResponse {
  total: number
  new: number
  in_progress: number
  lost: number
  with_offer: number
  without_offer: number
  perc_with_offer: number
  hours_until_in_progress: number
  hours_until_with_offer: number
}

export interface DashboardLoansStatResponse extends DashboardStatResponse {
  total: number
  perc_loans_vs_sales: number
  loans_per_bike: number
  km_per_loan: number
}

export interface DashboardAppointmentsStatResponse extends DashboardStatResponse {
  total: number
  finalized: number
  not_finalized: number
  perc_finalized: number
}

export interface DashboardRegistrationsStatResponse extends DashboardStatResponse {
  sales_total: number
  sales_hp: number
  sales_no_hp: number
  perc_regs_fin: number
  perc_regs_hm: number
  perc_regs_ins: number
  perc_sales_hp: number
  perc_sales_hp_prev_year: number
  regs_hp: number
  regs_hp_diff_prev_year: number
  regs_hp_prev_year: number
}

export type BrowserDetection = 'chrome' | 'firefox' | 'safari' | 'opera' | 'edge' | 'unknown'

export interface DeviceDetection {
  browser: BrowserDetection
  os: OSDetection
  isMobile: boolean
  isDesktop: boolean
}

export type OSDetection = 'ios' | 'android' | 'windows' | 'mac' | 'linux' | 'unknown'

export enum RegistrationFilters {
  PendingSign = 'pending_sign',
  PendingRegister = 'pending_register',
  PendingCommunication = 'pending_communication',
}

export enum RegistrationArchivedFilters {
  ArchivedPending = 'archived_pending',
  ArchivedTotal = 'archived_total',
}

export interface ComposedGraphData {
  type: EchartsGraphType
  data: GraphData[]
  seriesOptions?: EchartsOption['series']
  echartsOptions?: Omit<EchartsOption, 'series'>
}

export type InternalGraphData = { seriesOptions?: Pick<EchartsOption, 'series'> } & GraphData

export interface WebSocketDeviceState {
  battery: number
  data: any
  id: string
  lastTs: number
  lat: number
  lng: number
  model: string
  odometer: number
  status: string
  updatedAt: string
  version: string
  vin: string
  voltage: number
}

export interface GoogleMapsPosition {
  lat: number
  lng: number
}

export type MapitDeviceStatus = 'AT_REST' | 'MOVING' | 'BUZZ' | 'NO_COMMS'

export interface ModelWithDescription extends Model {
  description: string
}

export interface MaterialWithDescription extends Material {
  description: string
}

export interface CampaignWithDescription extends Campaign {
  description: string
  isNotAvailable?: boolean
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type RegistrationPlatform = 'HONDA_MAPIT' | 'MAPIT_CONNECT'

export type RegistrationVehicleStatus = 'NEW' | 'USED' | 'TRANSFERRED'
export interface SendOfferByEmailResponse {
  success: boolean
}
