import { render, staticRenderFns } from "./OfferTable.vue?vue&type=template&id=043524e6&scoped=true&"
import script from "./OfferTable.vue?vue&type=script&lang=ts&"
export * from "./OfferTable.vue?vue&type=script&lang=ts&"
import style0 from "./OfferTable.vue?vue&type=style&index=0&id=043524e6&scoped=true&lang=css&"
import style1 from "./OfferTable.vue?vue&type=style&index=1&global=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "043524e6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCheckbox,VCol,VDataTable,VDivider,VIcon,VList,VListItem,VListItemTitle,VMenu,VRow,VTooltip})
