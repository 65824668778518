var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{},attrs:{"disable-sort":_vm.disableTableSort,"id":_vm.id,"loading":_vm.loading,"headers":_vm.fields,"items":_vm.sortedItems,"no-data-text":_vm.$t(
      _vm.items && _vm.items.length > 0
        ? 'common.no-data-search'
        : 'common.no-data',
      _vm.searchTerm
    ),"no-results-text":_vm.$t('common.no-data-search'),"loading-text":_vm.loadingText,"footer-props":{
    'items-per-page-text': ((_vm.$t('common.rows-per-page')) + ":"),
  },"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sort-by":_vm.updateSorting,"update:sort-desc":_vm.updateOrdering},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',_vm._l((_vm.fields),function(header,index){return _c('td',{key:index,class:['text-left', header.rowClass ? header.rowClass : '']},[_c(header.name,{tag:"component",attrs:{"row-data":props.item},on:{"click-field-event":_vm.handleFieldEvent}})],1)}),0)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }