<template>
  <th class="align-left" v-if="isHeader">
    {{ title }}
  </th>
  <div v-else-if="rowData">
    <span class="f-s-14" v-if="showField(rowData.request, 'service')">
      <b>Revisión</b><br />
    </span>
    <span class="f-s-14" v-if="showField(rowData.request, 'tyres')">
      Cambio de neumáticos<br />
    </span>
    <span class="f-s-14" v-if="showField(rowData.request, 'paint')">
      Reparación chapa y pintura<br />
    </span>
    <span class="f-s-14" v-if="showField(rowData.request, 'failure')">
      Reparación mecánica<br />
    </span>
    <span class="f-s-14" v-if="showField(rowData.request, 'other')">
      Otras reparaciones<br />
    </span>
  </div>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

export default {
  mixins: [VuetableFieldMixin],
  props: {
    rowData: {
      type: Object,
      default: () => {
        return { request: { requested: null } };
      }
    }
  },
  methods: {
    showField: (request, key) => {
      let ret = false;
      if (request && request.requested && request.requested[key]) {
        ret = true;
      }
      return ret;
    }
  },
  name: 'Reason'
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>
