<template>
  <div class="contact-search-bar" :class="{'contact-search-bar--focused': isDropdownOpen}">
    <vue-tel-input
      v-model="prefix"
      :defaultCountry="defaultCountry"
      :inputOptions="{ showDialCode: true }"
      :preferredCountries="preferredCountries"
      :allCountries="allCountries"
      :dropdownOptions="{
        showSearchBox: true,
        showFlags: true,
        showDialCodeInList: true,
      }"
      @open="handlePrefixOpen"
      @close="() => { isPrefixSelectOpen = false }"
      @country-changed="handleCountryChange"
      class="contact-search-bar__select-prefix"
    >
      <template v-slot:arrow-icon>
        <div class="contact-search-bar__arrow-container">
          <v-icon class="fas fa-sort-up" :class="{'rotated-icon': !isPrefixSelectOpen} " size="13" />
          <span>{{ prefix }}</span>
        </div>
      </template>
    </vue-tel-input>
    <input
      v-model="phoneNumber"
      @keypress="handleKeyPress"
      @keyup.enter="handleSearchContact"
      @input="handlePhoneChange"
      @focus="() => { isDropdownOpen = true }"
      data-cy="search-contact-field"
      ref="phoneInput"
      :placeholder="$t('contact.search-bar.placeholder')"
      type="text"
      class="contact-search-bar__telephone-input"
    />
    <m-btn
      class="contact-search-bar__button contact-search-bar__button--primary contact-search-bar__button--search-bar"
      variant="outlined"
      :loading="isLoading"
      :disabled="!isValidPhone"
      @click="handleSearchContact"
    >
      <div>
        <v-icon class="fas fa-search" size="15" style="margin-bottom: 2px;"/>
        <span>{{ $t('buttons.search') }}</span>
      </div>
    </m-btn>
    <ul v-if="isDropdownOpen && isContactSearched" class="contact-search-bar__options">
      <template v-if="client">
        <li :key="client.phone" class="contact-search-bar__option" :class="{ 'contact-search-bar__option--offer': isOffer }">
          <div class="contact-search-bar__option-info">
            <p class="contact-search-bar__info">{{`${client.firstName || '-'} ${client.lastName || ''}`}}</p>
            <span class="contact-search-bar__info contact-search-bar__info--secondary">{{ client.phone | formatPhone}}</span>
            <span class="contact-search-bar__info contact-search-bar__info--secondary">{{ client.email }}</span>
          </div>
          <div class="contact-search-bar__option-buttons">
            <m-btn
              class="contact-search-bar__button"
              :class="{ 'contact-search-bar__button--view-history': !isOffer}"
              variant="outlined"
              @click="onSeeClientHistory"
            >
              <div>
                <span>{{ clientHistoryTextButton }}</span>
              </div>
            </m-btn>
            <m-btn
              class="contact-search-bar__button contact-search-bar__button--primary"
              variant="outlined"
              data-cy="btn-next"
              v-if="isOffer"
              @click="onCreateOfferMotorbike"
            >
              <div>
                <v-icon class="fak fa-light fa-m-file-plus" size="19" />
                <span>{{ actionFoundButtonText }}</span>
              </div>
            </m-btn>
          </div>
        </li>
      </template>
      <template v-if="!client && isContactSearched">
        <li class="contact-search-bar__no-option">
          <v-icon class="fa-light fa-user" color="primary" size="24"/>
          <p class="contact-search-bar__no-option-title">{{$t('contact.search-bar.no-client')}}</p>
          <p class="contact-search-bar__no-option-subtitle">{{ noClientSubtitle }}</p>
          <div class="contact-search-bar__no-option-buttons" v-if="!isOffer">
            <m-btn
              class="contact-search-bar__button"
              variant="outlined"
              @click="onCreateOfferMotorbike"
              :disabled="isCreateDisabled"
            >
              <div>
                <v-icon class="fak fa-light fa-m-file-plus" size="19" />
                <span>{{ $t('buttons.create-offer') }}</span>
              </div>
            </m-btn>
            <m-btn
              class="contact-search-bar__button contact-search-bar__button--primary"
              variant="outlined"
            >
              <div>
                <v-icon class="fa-light fa-bullseye-arrow" size="19"/>
                <span>{{ $t('buttons.create-lead') }}</span>
              </div>
            </m-btn>
          </div>
          <div class="contact-search-bar__no-option-button" v-if="isOffer">
            <m-btn
              class="contact-search-bar__button contact-search-bar__button--primary"
              variant="outlined"
              @click="onCreateOfferMotorbike"
              data-cy="btn-next"
            >
              <div>
                <v-icon class="fak fa-light fa-m-file-plus" size="19" />
                <span>{{ $t('buttons.create-offer') }}</span>
              </div>
            </m-btn>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import { getExampleNumber, parsePhoneNumber, getCountryCallingCode } from 'libphonenumber-js'
import examples from 'libphonenumber-js/mobile/examples'
import Inputmask from "inputmask";
import { ContactsApi } from "@/api/contacts.api"
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'
import { getAllCountriesWithPrefixes } from '@/utils/countries'
  export default {
    name: 'ContactSearchBar',
    components: { VueTelInput },
    props: [ 'noClientSubtitle', 'isOffer', 'clientHistoryTextButton', "actionFoundButtonText", 'fromHeader' ],
    data: () => ({
      phoneNumber: '',
      searchPrefixQuery: '',
      prefix: '',
      client: null,
      isPrefixSelectOpen: false,
      isDropdownOpen: false,
      isLoading: false,
      placeholder: "",
      prefixCountrySelected: "",
      isValidPhone: false,
      isContactSearched: false,
      preferredCountries: ['ES', 'PT'],
    }),

    mounted() {
      document.addEventListener('click', this.handleClickOutside);
    },

    computed: {
      ...mapGetters({
        group: 'getGroup'
      }),
      allCountries(){
        return getAllCountriesWithPrefixes(this.group.locale.split('-')[0])
      },
      defaultCountry(){
        return this.group.branch
      },
      isCreateDisabled() {
        return this.$route.path === '/offer-manager/create' && this.fromHeader
      }
    },

    beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside);
    },

    methods: {
      ...mapActions(['showAlertSuccess',  'setAlertResult']),

      async onCreateOfferMotorbike() {
        if (!this.client) {
          this.client = {
            phone: this.getFormattedPhoneNumber(),
          }
        } else {
          this.client = {...this.client, gdprConsent: true, privacyTermsAccepted: true}
        }

        this.$store.commit('SET_OFFER_SEARCHED_CONTACT', this.client)
        this.$store.commit('SET_FROM_HEADER', !!this.fromHeader)
        this.sendGAEvent('InitOffer', 'Steps')

        await router.push({ name: 'CreateOfferMotorbike' })

        this.resetSearchBar()
      },

      async onSeeClientHistory(){
        const { id } = this.client
        await router.push({ name: 'ClientHistory', params: { id } })
        this.resetSearchBar()
      },

      async handleSearchContact(){
        if (!this.isValidPhone) return;

        this.isLoading = true
        this.isContactSearched = false

        try {
          const res = await new ContactsApi().getContactByPhone(this.getFormattedPhoneNumber())
          this.client = res.data.data.length ? res.data.data[0] : null

          this.isContactSearched = true
          this.isDropdownOpen = true
        } catch (error) {
          this.setAlertResult({
            type: 'error',
            stringId: 'toast.search-contact.error',
            open: true,
          })
        } finally {
          this.isLoading = false
        }
      },

      handleClickOutside(event) {
        const isClickInside = this.$el.contains(event.target);

        if (!isClickInside) {
          this.isDropdownOpen = false;
        }
      },

      handlePrefixOpen(){
        this.isPrefixSelectOpen = true
        this.isDropdownOpen = false
        this.$nextTick(() => {
          const searchInput = document.querySelector('.vti__search_box');
          searchInput.style.setProperty('--placeholder-color', '#969696');

          if (searchInput) {
            searchInput.placeholder = this.$t("contact.search-bar.search-country");

            searchInput.addEventListener('input', ({ target: { value } }) => {
              searchInput.placeholder = this.$t("contact.search-bar.search-country");

              if (value) {
                this.preferredCountries = []
                return
              }

              searchInput.style.setProperty('--placeholder-color', 'transparent');
              this.preferredCountries = ['ES', 'PT']
            })
          }
        });
      },

      handleKeyPress(event) {
        const key = event.key;
        const value = event.target.value;

        if (!/^\d$/.test(key)) {
          event.preventDefault();
        }

        if (value.length) {
          event.preventDefault();
        }
      },

      handleCountryChange({ iso2 }){
        const nationalNumber = getExampleNumber(iso2, examples)?.formatNational();
        const formattedNationalNumber = nationalNumber.startsWith('0') ? nationalNumber.slice(0, -1) : nationalNumber;
        const mask = formattedNationalNumber.replace(/\d/g, ' 9').trim();
        this.prefixCountrySelected = iso2;
        this.resetSearchBar();

        Inputmask({
          mask,
          placeholder: "_",
          clearIncomplete: false,
          showMaskOnHover: false,
          showMaskOnFocus: true,
        }).mask(this.$refs.phoneInput);
      },

      handlePhoneChange({ target: { value } }){
        this.isValidPhone = this.validatePhoneNumber(value);
        this.client = null;
        this.isContactSearched = false;
      },

      validatePhoneNumber(value) {
        const formattedValue = value.replace(/[\s_]/g, '');
        const exampleNumber = getExampleNumber(this.prefixCountrySelected, examples);

        try {
          const phoneNumber = parsePhoneNumber(formattedValue, this.prefixCountrySelected);

          return phoneNumber?.isValid() && formattedValue.length === exampleNumber?.nationalNumber.replace(/\s/g, '').length;
        } catch (error) {
          return false;
        }
      },

      getFormattedPhoneNumber() {
        if(this.validatePhoneNumber(this.phoneNumber)){
          const formattedPhone = this.phoneNumber.replace(/[\s_]/g, '')
          const countryCode = getCountryCallingCode(this.prefixCountrySelected)

          return `+${countryCode}${formattedPhone}`
        }

        return null
      },

      resetSearchBar(){
        this.phoneNumber = ''
        this.isValidPhone = false
        this.isContactSearched = false
        this.isDropdownOpen = false
        this.isPrefixSelectOpen = false
        this.client = null
      },

      async sendGAEvent(action, label) {
        await this.$gtm.trackEvent({ category: 'Offers', action, label })
      },
    }
  }
</script>
<style lang="scss">
  $_height-select: 250px;
  $_box-shadow-prefix: rgba(0, 0, 0, 0.16) 3px 3px 6px 0px;

  #app button.v-btn.contact-search-bar__button{
    border-color: #9a9a9a;
    border-radius: 8px !important;
    background-color: white;
    cursor: pointer;
    height: 38px;
    transition: background-color 0.2s ease-in, border-color 0.2s ease-in;

    div{
      padding: 0 12px;

      i{
        margin-right: 8px;
      }
    }

    &:hover{
      border-color: black;

      div{
        color: black;
      }
    }

    &--primary{
      background-color: #00803F;
      border-color: transparent;

      div{
        color: white;
      }

      &:hover{
        background-color: #006B37;
        border-color: transparent;

        div{
          color: white;
        }
      }

      &:disabled{
        background-color: #8C8C8CA6;
        cursor: not-allowed;

        div{
          color: #dedede;

          .v-icon{
            color: #dedede !important;
          }
        }
      }
    }

    &--view-history{
      height: 32px !important;
      transition: border-color 0.2s ease-in;
      color: rgba(0,0,0,0.86) !important;;
    }

    &--search-bar{
      height: 32px;
      margin-right: 4px;
      border-radius: 3px !important;
    }
  }

  .contact-search-bar{
    $self:&;
    position: relative;
    font-family: 'ProximaNovaRegular';
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    background-color: #f2f2f2;
    border-radius: 4px;
    width: inherit;
    height: 40px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.24);
    transition: background-color 0.2s ease-in, color 0.2s ease-in, box-shadow 0.2s ease-in;

    &:hover{
      box-shadow: 0 0 0 2px rgb(167, 3, 3);
    }

    &--focused{
      box-shadow: 0 0 0 1px #1976d2;
      background-color: white;
    }

    &:focus-within{
      box-shadow: 0 0 0 1px #1976d2;
      background-color: white;
    };

    &__select-prefix{
      #{$self}__arrow-container{
        display: flex;
        gap: 6px;
        align-items: center;
        font-size: 16px;
        padding-left: 7px;

      }

      .v-icon{
        &::before{
          height: 8.13px;
        }
      }
    }

    &__telephone-input{
      all: unset;
      width: 100%;
      padding: 10px 16px 10px 0;
      font-family: 'ProximaNovaRegular';
      font-variant-numeric: tabular-nums;
      color: #000;
      text-align: center;

      &::placeholder{
        color: ccc;
      }
    }

    &__options {
      position: absolute;
      background-color: white;
      top: 100%;
      left: -1px;
      width: inherit;
      margin-top: 2px;
      max-height: $_height-select;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 !important;
      border: 1px solid #ccc;
      border-radius: 0 0 8px 8px;
      box-shadow: $_box-shadow-prefix;
      width: calc(100% + 1px);

      li {
        list-style: none;
      }

      #{$self}__option{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        padding: 16px 12px;
        cursor: pointer;
        transition: background-color 0.2s ease-in;

        &--offer{
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;

          #{$self}__option-buttons{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }

        &:hover{
          background-color: #eee;

          .v-btn.contact-search-bar__button{
            border-color: black;
            color: black;
          }
        }

        #{$self}__option-info{
          overflow: hidden;

          #{$self}__info {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0;
            line-height: 1.2;

            &:first-child{
              padding-bottom: 2px;
            }

            &--secondary{
              color: #898989;
              display: block;
            }
          }
        }
      }

      #{$self}__no-option{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 16px;
        transition: background-color 0.2s ease-in;

        &:hover{
          background-color: #eee;
        }

        p {
          margin-bottom: 0;
          margin-top: 7px;
          line-height: 1.1;
        }

        #{$self}__no-option-title{
          font-size: 20px;
          max-width: 280px;
        }

        #{$self}__no-option-subtitle{
          font-size: 14px;
          color: #898989;
        }

        #{$self}__no-option-buttons{
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          margin-top: 20px;
          width: 100%;
          gap: 10px;
        }

        #{$self}__no-option-button{
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          margin-top: 20px;
          width: 100%;
        }
      }
    }

    .rotated-icon {
      transform: rotate(180deg);
      transition: transform 0.3s ease;
    }
  }

  // STYLES VEL SEL INPUT //

  div.vue-tel-input{
    border: none;

    &:focus-within{
      box-shadow: none;
    }
  }

  input.vti__search_box{
    display: block;
    width: 93.5%;
    margin: 8px 12px;
    border-radius: 4px;
    padding: 8px 12px;
    height: 40px;
    position: relative;

    &:focus, &:hover {
      border-color: #1976d2;
    }

    &::placeholder {
      color: var(--placeholder-color, '#969696');
    }
  }

  .vti__input{
    display: none;
  }

  span.vti__flag{
    margin-left: 0;
    transform: scale(1.25);
    transform-origin: left center;
  }

  div.vti__dropdown{
    padding: 8px 4px;
    min-width: 84px;
    position: unset;
    transition: background-color 0.2s ease-in;
    border-radius: 4px 0 0 4px;
  }

  ul.vti__dropdown-list.below{
    position: absolute;
    top: 40px;
    width: calc(100% + 2px) !important;
    max-height: $_height-select;
    padding-left: 0;
    border-radius: 0 0 8px 8px;
    box-shadow: $_box-shadow-prefix;

    li.vti__dropdown-item:first-of-type {
      border-top: 1px solid #cacaca;
    }
  }

  li.vti__dropdown-item{
    display: flex;
    align-self: center;
    gap: 4px;
    padding: 16px 12px;

    span{
      color: #898989;
    }

    span.vti__flag{
      transform-origin: left top;
    }
  }
</style>
