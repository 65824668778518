import { getCountries, getCountryCallingCode } from "libphonenumber-js"

export function getAllCountriesWithPrefixes(locale: string){
  const countries = getCountries()
  return countries.map(countryCode => ({
    name: new Intl.DisplayNames(locale, { type: 'region' }).of(countryCode),
    iso2: countryCode,
    dialCode: getCountryCallingCode(countryCode),
    priority: 0,
    areaCodes: null
  }))
}