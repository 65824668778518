<template>
  <v-row>
    <v-col class="col-5 col-md-5 col-sm-12 bold-900">
        KILÓMETROS
    </v-col>
    <v-col class='col-7 col-md-7 col-sm-12'>
      <v-row no-gutters>
        <v-col cols="6" class="col-md-6 col-sm-12">
          <v-row no-gutters>
            <v-col class='col-12 subtitle'>
              <small>KM EN EL MOMENTO DE LA REPARACIÓN O MANTENIMIENTO</small>
            </v-col>
            <v-col class="col-12">
              <text-field :rules="`min_value:${inspection.lastService}`" name="km" type="number" v-model='currentRevision.km'
                          placeholder="Km de la moto"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="col-md-6 col-sm-12">
          <v-row no-gutters>
            <v-col class="col-12 subtitle">
              <small>FECHA DE LA REPARACIÓN</small>
            </v-col>
            <v-col class="col-12">
              <date-picker :initValue="now" :clearable="false" :not-before="lastInspectionDate" :not-after="now" v-model="currentRevision.day"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import DatePicker from '@/components/general/DatePicker';
  import TextField from '@/components/general/TextField';
  import moment from 'moment';

  export default {
    inject: ['$validator'],
    components: {
      DatePicker, TextField
    },
    computed: {
      now() {
        return new Date();
      },
      lastInspectionDate(){
        const formattedDate = moment(this.inspection.createdDate, 'DD/MM/YYYY').format('MM/DD/YYYY')
        return new Date(formattedDate)
      }
    },
    props: ["currentRevision", "inspection"]
  }
</script>
<style scoped>
  .redbg {
    color: red !important;
  }
  .subtitle{
    font-family: 'ProximaNovaExCnLight','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 14px;
    color: #888;
  }
</style>
