<template>
  <th v-if="isHeader">
    {{ title }}
  </th>
  <div v-else-if="rowData">
    <span class="f-s-14">
      <b>{{ getTime(rowData.request) }}</b>
    </span>
  </div>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

export default {
  mixins: [VuetableFieldMixin],
  props: {
    rowData: {
      type: Object,
      default: () => {
        return { request: { appointment: { hour: '-' } } };
      }
    }
  },
  methods: {
    getTime: (request) => {
      return (request && request.appointment) ? request.appointment.hour : '';
    }
  },
  name: 'Time'
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>
