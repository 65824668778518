<template>
  <th class="b-r-1 align-left" v-if="isHeader">
    {{ title }}
  </th>
  <span v-else-if="rowData">
    {{ getTime(rowData.request) }}
  </span>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'

export default {
  mixins: [VuetableFieldMixin],
  props: {
    rowData: {
      type: Object,
      default: () => {
        return { request: { pickup: { hour: '-' } } }
      },
    },
  },
  methods: {
    getTime: (request) => {
      return request && request.pickup ? request.pickup.hour : ''
    },
  },
  name: 'Time',
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>
