<template>
    <v-data-table
    :disable-sort="disableTableSort"
    style="style"
    :id="id"
    :loading="loading"
    :headers="fields"
    :items="sortedItems"
    :no-data-text="
      $t(
        items && items.length > 0
          ? 'common.no-data-search'
          : 'common.no-data',
        searchTerm,
      )
    "
    :no-results-text="$t('common.no-data-search')"
    :loading-text="loadingText"
    :footer-props="{
      'items-per-page-text': `${$t('common.rows-per-page')}:`,
    }"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    @update:sort-by="updateSorting"
    @update:sort-desc="updateOrdering"
  >
    <template v-slot:item="props">
      <tr>
        <td
          v-for="(header, index) in fields"
          :key="index"
          :class="['text-left', header.rowClass ? header.rowClass : '']"
        >
          <component
            :is="header.name"
            :row-data="props.item"
            @click-field-event="handleFieldEvent"
          />
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import getComponent from '../tablecolumns'

const prepareTableHeaders = (headers) => {
  return headers.map((header) => {
    return {
      ...header,
      name: getComponent(header.value),
      sortable: header.sortable ?? false,
    }
  })
}

const getNestedValue = (obj, path, defaultValue = '') => {
  return path
    .split('.')
    .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : defaultValue), obj)
}
export default {
  name: 'DataTableBase',
  props: {
    id: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    searchTerm: {
      type: String,
      default: '',
    },
    style: {
      type: String,
      default: '',
    },
    loadingText: {
      type: String,
      default: undefined,
    },
    path: {
      type: String,
      default: '',
    },
    compareFn: {
      type: Function,
      required: false,
      default: (valA, valB) => {
        if (valA < valB) return -1
        if (valA > valB) return 1
        return 0
      },
    },
    disableTableSort: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: prepareTableHeaders(this.headers),
      sortBy: [],
      sortDesc: [],
    }
  },
  computed: {
    sortedItems() {
      if (!this.sortBy.length) return this.items
      const res = [...this.items].sort((a, b) => {
        for (let i = 0; i < this.sortBy.length; i++) {
          const key = this.sortBy[i]
          const desc = this.sortDesc[i] ?? false
          const header = this.fields.find((h) => h.value === key)
          if (!header || !header.path) continue

          const valA = getNestedValue(a, header.path)
          const valB = getNestedValue(b, header.path)

          if (valA == null || valB == null) continue

          let comparison = 0
          if (typeof valA === 'string' && typeof valB === 'string') {
            comparison = this.$props.compareFn(valA, valB)
          } else {
            comparison = valA < valB ? -1 : valA > valB ? 1 : 0
          }

          if (comparison !== 0) return desc ? -comparison : comparison
        }

        return 0
      })
      return res
    },
  },
  methods: {
    handleFieldEvent(eventData) {
      this.$emit('openModal', eventData)
    },
    updateSorting(sortBy) {
      this.sortBy = sortBy
    },
    updateOrdering(sortDesc) {
      this.sortDesc = sortDesc
    },
  },
}
</script>
