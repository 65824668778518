<template>
  <th class='b-r-1' v-if="isHeader">
    {{ title }}
  </th>
  <span class="f-s-14" v-else>
    <b>{{ rowData.inspection.nextService }}</b>
  </span>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

export default {
  mixins: [VuetableFieldMixin],
  props: {
    rowData: {
      type: Object,
      default: () => {
        return { inspection: { prevision: '-' } };
      }
    }
  },
  name: 'KmNextRevision'
}
</script>
<style src="@/styles/styled_cell.less" lang="less" scoped="true"></style>
